import customDataProvider from 'src/refine-tools/dataProvider'
import { GraphQLClient } from 'graphql-request'
import { API_URL } from 'src/configs'

/**
 * Get DataProviders function return all data providers.
 *
 * @param authorization Authorization.
 * @returns Data Providers.
 */
export const getDataProviders = (authorization: string) => {
    /**
     * Default Data Provider.
     */
    const client = customDataProvider(
        new GraphQLClient(API_URL, {
            headers: {
                authorization: authorization,
            },
        }),
    )

    /**
     * Active Nrlinks Data Provider.
     */
    // TODO - take off this two providers and add a gateway adapted to graphql
    const nrlinksDataProvider = customDataProvider(
        new GraphQLClient(`${API_URL}/nrlinks`, {
            headers: {
                authorization: authorization,
            },
        }),
    )

    /**
     * Networks Data Provider.
     */
    // TODO - take off this two providers and add a gateway adapted to graphql
    const networksDataProvider = customDataProvider(
        new GraphQLClient(`${API_URL}/networks`, {
            headers: {
                authorization: authorization,
            },
        }),
    )

    /**
     * Logistic Data Provider.
     */
    const logisticDataProvider = customDataProvider(
        new GraphQLClient(`${API_URL}/logistic`, {
            headers: {
                authorization: authorization,
            },
        }),
    )

    return {
        default: client,
        nrlinksDataProvider,
        networksDataProvider,
        logisticDataProvider,
    }
}
