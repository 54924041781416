import React from 'react'
import { Divider, Row, Col, Card, Avatar, Typography } from 'antd'
import { nrlinkWidgetContentType } from 'src/modules/NrlinksManagement/NrlinkWidgets/NrlinkWidgets'
import { useNrlinkWidgets } from 'src/modules/NrlinksManagement/NrlinkWidgets/nrlinkWidgetsHook'
import NrlinkWidgetFilterForm from 'src/modules/NrlinksManagement/NrlinkWidgets/NrlinkWidgetFilterForm'
import { get, isNull } from 'lodash'
import 'src/modules/NrlinksManagement/NrlinkWidgets/NrlinkWidgets.css'

const widgetList: nrlinkWidgetContentType[] = [
    {
        bgColor: 'deepskyblue',
        title: 'nrLINK remontant de la donnée',
        name: 'nrlinksWithConsumptionDataCount',
    },
    {
        bgColor: 'palegoldenrod',
        title: 'nrLINK avec consentement actif',
        name: 'nrlinksWithActiveConsentCount',
    },
    {
        bgColor: 'gold',
        title: 'nrLINK avec consentement actif et remontant de la donnée',
        name: 'nrlinksWithConsumptionDataAndActiveConsentCount',
    },
]

/**
 * Nrlink Widgets component.
 *
 * @returns Nrlink Widgets component.
 */
const NrlinkWidgets = () => {
    const { isLoadingInProgress, loadNrlinkStatistics, nrlinkStatistics } = useNrlinkWidgets()

    return (
        <div className="NrlinkWidgets">
            <NrlinkWidgetFilterForm isLoadingInProgress={isLoadingInProgress} onSubmit={loadNrlinkStatistics} />
            <Divider style={{ userSelect: 'none' }} />
            <Row gutter={48} style={{ marginTop: '16px' }}>
                {widgetList.map((widget) => (
                    <Col sm={24} md={8} key={widget.title} style={{ marginBottom: '16px' }}>
                        <Card
                            className="NrlinkWidgetsCard"
                            loading={isLoadingInProgress || isNull(nrlinkStatistics)}
                            hoverable
                            style={{
                                height: '100%',
                                userSelect: 'none',
                            }}
                        >
                            <Card.Meta
                                style={{ height: '100%' }}
                                avatar={<Avatar size={64} style={{ backgroundColor: widget.bgColor }} />}
                                title={
                                    <Typography.Title level={5} style={{ margin: 0, whiteSpace: 'break-spaces' }}>
                                        {widget.title}
                                    </Typography.Title>
                                }
                                description={
                                    <Typography.Title level={2} type="secondary" style={{ margin: 0 }}>{`${get(
                                        nrlinkStatistics,
                                        widget.name,
                                    )}`}</Typography.Title>
                                }
                            />
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    )
}

export default NrlinkWidgets
