import { providerResourceConfig, providerRoutes } from 'src/modules/SupplyContracts/Providers/ProviderConfig'
import {
    commercialOffersResourceConfig,
    commercialOffersRoutes,
} from 'src/modules/SupplyContracts/CommercialOffers/CommercialOffersConfig'
import { TariffTypesResourceConfig, tariffTypesRoutes } from 'src/modules/SupplyContracts/TariffTypes/TariffTypesConfig'
import {
    ContractTypesResourceConfig,
    contractTypesRoutes,
} from 'src/modules/SupplyContracts/ContractTypes/ContractTypesConfig'
import { ContractsResourceConfig, contractsRoutes } from 'src/modules/SupplyContracts/Contracts/ContractsConfig'
import {
    TariffComponentsResourceConfig,
    tariffComponentsRoutes,
} from 'src/modules/SupplyContracts/TariffComponents/TariffComponentsConfig'
import { SupplyContractsMenuConfigName } from 'src/configs'
import { RouteProps } from 'react-router-dom'

/**
 * SupplyContractsConfig.
 */
export const SupplyContractsConfig = [
    {
        name: SupplyContractsMenuConfigName,
        meta: {
            label: 'Contracts de fourniture',
            dataProviderName: 'default',
        },
    },
    { ...providerResourceConfig },
    { ...commercialOffersResourceConfig },
    { ...TariffTypesResourceConfig },
    { ...ContractTypesResourceConfig },
    { ...TariffComponentsResourceConfig },
    { ...ContractsResourceConfig },
]

/**
 * SupplyContracts Routes.
 */
export const SupplyContractsRoutes: RouteProps[] = [
    ...providerRoutes,
    ...commercialOffersRoutes,
    ...tariffTypesRoutes,
    ...contractTypesRoutes,
    ...tariffComponentsRoutes,
    ...contractsRoutes,
]
