import {
    ChameleonDeliveriesResourceConfig,
    chameleonDeliveriesRoutes,
} from 'src/modules/Logistics/ChameleonDeliveries/ChameleonDeliveriesConfig'
import { StoresResourceConfig, storesRoutes } from 'src/modules/Logistics/Stores/StoresConfig'
import { LogisticMenuConfigName } from 'src/configs'
import { RouteProps } from 'react-router-dom'
import { NrlinksStockResourceConfig, nrlinksStockRoutes } from './NrlinksStock/NrlinksStockConfig'

/**
 * LogisticsConfig.
 */
export const LogisticsConfig = [
    {
        name: LogisticMenuConfigName,
        meta: {
            label: 'Logistics',
        },
    },
    { ...ChameleonDeliveriesResourceConfig },
    { ...StoresResourceConfig },
    { ...NrlinksStockResourceConfig}
]

/**
 * Logistics Routes.
 */
export const LogisticsRoutes: RouteProps[] = [...chameleonDeliveriesRoutes, ...storesRoutes, ...nrlinksStockRoutes]
