import React from 'react'
import { Edit, useForm, ListButton } from '@refinedev/antd'
import { Form, Input } from 'antd'
import { IStore } from 'src/modules/Logistics/Stores/Stores.d'
import { useParams } from 'react-router-dom'
/**
 * This function returns a view to edit stores.
 *
 * @returns View to Edit stores.
 */
export const EditStores = () => {
    const { id } = useParams()
    const { formProps, saveButtonProps } = useForm<IStore>({
        dataProviderName: 'logisticDataProvider',
        action: 'edit',
        resource: 'store',
        id: id,
        queryOptions: {
            enabled: true,
        },
        meta: {
            operation: 'allStores', // the query operation
            fields: [
                {
                    edges: [
                        {
                            node: ['id', 'name', 'location', 'url', 'adminUrl'],
                        },
                    ],
                },
            ],
        },
    })

    return (
        <Edit
            saveButtonProps={saveButtonProps}
            headerProps={{
                title: 'Modifier les détails du store',
                extra: <ListButton resource="store" />,
            }}
        >
            <Form
                {...formProps}
                layout="vertical"
                onFinish={(values) =>
                    formProps.onFinish?.({
                        ...values,
                    })
                }
            >
                <Form.Item
                    label="Nom du store"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Localisation"
                    name="location"
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Url de la plateforme associer" name="url">
                    <Input />
                </Form.Item>
                <Form.Item label="Url de l'admin associé au store" name="adminUrl">
                    <Input />
                </Form.Item>
            </Form>
        </Edit>
    )
}
