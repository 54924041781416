/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Create, useForm } from '@refinedev/antd'
import { Checkbox, Form, Input, Select } from 'antd'
import { SelectEcoGestRooms } from 'src/modules/Advices/EcoGestRooms'
import { SelectEcoGestPoles } from 'src/modules/Advices/EcoGestPoles'
import { IEcoGest } from 'src/modules/Advices/EcoGests/EcoGests.d'
import { energies, equipments, homeConfiguration } from 'src/modules/Advices/EcoGests/utils'

/**
 * This function returns a view to create one EcoGest.
 *
 * @returns View to create one EcoGest.
 */
export const CreateEcoGests = () => {
    const { formProps, saveButtonProps, onFinish } = useForm<IEcoGest>()

    /**
     * This function is called when the form is submitted.
     *
     * @param values Values of the form. (Cannot type cast it to IEcoGest).
     */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onFinishHandler = (values = {} as unknown as IEcoGest) => {
        onFinish({
            ...values,
            equipments: JSON.stringify(values.equipments),
            energies: JSON.stringify(values.energies),
            homeConfigurations: JSON.stringify(values.homeConfigurations),
        })
    }

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} onFinish={(v) => onFinishHandler(v as unknown as any)}>
                <Form.Item
                    label="Titre"
                    name="title"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="% d'Economie"
                    name="percentageSaved"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Url de l'icône"
                    name="urlIcon"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Informations"
                    name="infos"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="A la une" name="highlighted" valuePropName="checked">
                    <Checkbox />
                </Form.Item>
                <Form.Item name="poleIds" label="Postes de consommations">
                    <SelectEcoGestPoles />
                </Form.Item>
                <Form.Item name="roomIds" label="Pièces">
                    <SelectEcoGestRooms />
                </Form.Item>
                <Form.Item name="equipments" label="Equipements">
                    <Select mode="multiple" options={equipments} />
                </Form.Item>
                <Form.Item name="energies" label="Energies">
                    <Select mode="multiple" options={energies} />
                </Form.Item>
                <Form.Item name="homeConfigurations" label="Logement">
                    <Select mode="multiple" options={homeConfiguration} />
                </Form.Item>
            </Form>
        </Create>
    )
}
