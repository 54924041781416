import React from 'react'
import { RefreshButton, Show } from '@refinedev/antd'
import { Typography } from 'antd'
import { ITariffComponent } from 'src/modules/SupplyContracts/TariffComponents/TariffComponents.d'
import { useShow } from '@refinedev/core'
import { useParams } from 'react-router-dom'

/**
 * This enum is to convert the frequencys returned by the API.
 */
export enum daysOfWeek {
    //eslint-disable-next-line
    SU = 'Dimanche',
    //eslint-disable-next-line
    MO = 'Lundi',
    //eslint-disable-next-line
    TU = 'Mardi',
    //eslint-disable-next-line
    WE = 'Mercredi',
    //eslint-disable-next-line
    TH = 'Jeudi',
    //eslint-disable-next-line
    FR = 'Vendredi',
    //eslint-disable-next-line
    SA = 'Samedi',
}

/**
 * This function returns a view of a Tariff Component details.
 *
 * @returns View to show details of a Tariff Component.
 */
export const ShowTariffComponents = () => {
    const { id } = useParams()
    const { Title, Text } = Typography

    const { queryResult } = useShow<ITariffComponent>({
        resource: 'tariffComponent',
        id: id,
        meta: {
            operation: 'allTariffComponents', // the query operation
            fields: [
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'name',
                                'useMeterOffpeak',
                                'startTime',
                                'endTime',
                                'byweekday',
                                'freq',
                                'componentTariffType',
                                {
                                    tariffType: ['name'],
                                },
                                {
                                    contract: ['name'],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    })
    const { data, isLoading } = queryResult
    const record = data?.data

    return (
        <Show
            breadcrumb={null}
            isLoading={isLoading}
            title="Voir détails de la composante"
            headerProps={{
                extra: <RefreshButton onClick={() => queryResult.refetch()} />,
                subTitle: record?.name,
            }}
        >
            <Title level={5}>Nom de la composante</Title>
            <Text>{record?.name}</Text>

            {record?.contract ? (
                <div>
                    <Title level={5}>Relié à un contrat</Title>
                    <Text>{record?.contract?.name}</Text>
                </div>
            ) : (
                <div>
                    <Title level={5}>Relié à un type de tarif</Title>
                    <Text>{record?.tariffType?.name}</Text>
                </div>
            )}

            <Title level={5}>Fréquence </Title>
            <Text>{record?.freq}</Text>

            <Title level={5}>Appliquer aux jours </Title>
            <Text>{record?.byweekday.map((element) => `${daysOfWeek[element]}  `)} </Text>

            <Title level={5}>Utiliser le compteur heures creuses</Title>
            <Text>{record?.useMeterOffpeak ? 'Oui' : 'Non'}</Text>

            <Title level={5}>Type de tariff de la composante ?</Title>
            <Text>{record?.componentTariffType}</Text>

            <Title level={5}>Heure Début </Title>
            <Text>{record?.startTime ?? 'Non Spécifié'}</Text>

            <Title level={5}>Heure Fin </Title>
            <Text>{record?.endTime ?? 'Non Spécifié'}</Text>
        </Show>
    )
}
