import React from 'react'
import { RefreshButton, Show } from '@refinedev/antd'
import { Typography, Tag } from 'antd'
import { IEcoGest } from 'src/modules/Advices/EcoGests/EcoGests.d'
import { useShow } from '@refinedev/core'
import { useParams } from 'react-router-dom'
import { StarOutlined } from '@ant-design/icons'

/**
 * This function returns a view of one EcoGest.
 *
 * @returns View to show details of one EcoGest.
 */
export const ShowEcoGests = () => {
    const { Title, Text } = Typography
    const { id } = useParams()

    const { queryResult } = useShow<IEcoGest>({
        resource: 'ecoGest',
        id: id,
        meta: {
            operation: 'allEcoGests', // the query operation
            fields: [
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'title',
                                'description',
                                'percentageSaved',
                                'highlighted',
                                'urlIcon',
                                'infos',
                                'equipments',
                                'energies',
                                'homeConfigurations',
                                {
                                    poleTags: [
                                        {
                                            edges: [
                                                {
                                                    node: ['name', 'id', 'icon'],
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    roomTags: [
                                        {
                                            edges: [
                                                {
                                                    node: ['name', 'id', 'icon'],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    })
    const { data, isLoading } = queryResult
    const record = data?.data

    return (
        <Show
            breadcrumb={null}
            isLoading={isLoading}
            title="Détails de l'Ecogeste"
            headerProps={{
                extra: <RefreshButton onClick={() => queryResult.refetch()} />,
                subTitle: record?.title,
            }}
        >
            <Title level={5}>Titre</Title>
            <Text>{record?.title}</Text>
            <Title level={5}>Description</Title>
            <Text>{record?.description}</Text>
            <Title level={5}>{"% d'Economie"}</Title>
            <Text>{record?.percentageSaved}</Text>
            <Title level={5}>{"Url de l'icône"}</Title>
            <Text>{record?.urlIcon}</Text>
            <Title level={5}>Informations</Title>
            <Text>{record?.infos}</Text>
            <Title level={5}>A la une</Title>
            {record?.highlighted ? (
                <Tag color="gold">
                    <StarOutlined />
                </Tag>
            ) : (
                <Text>Non</Text>
            )}
            {record?.poleTags && (
                <div style={{ marginTop: '16px' }}>
                    <Title level={5}>Postes de consommations</Title>
                    {record.poleTags.edges.map(({ node }) => (
                        <Tag color="gold" key={node.id}>
                            {node.name}
                        </Tag>
                    ))}
                </div>
            )}
            {record?.roomTags && (
                <div style={{ marginTop: '16px' }}>
                    <Title level={5}>Pièces</Title>
                    {record.roomTags.edges.map(({ node }) => (
                        <Tag color="green" key={node.id}>
                            {node.name}
                        </Tag>
                    ))}
                </div>
            )}
        </Show>
    )
}
