import React from 'react'
import { RefreshButton, Show } from '@refinedev/antd'
import { Typography } from 'antd'
import { IEcoGestPole } from 'src/modules/Advices/EcoGestPoles/EcoGestPoles.d'
import { useShow } from '@refinedev/core'
import { useParams } from 'react-router-dom'

/**
 * This function returns a view of one EcoGestPole.
 *
 * @returns View to show details of one EcoGestPole.
 */
export const ShowEcoGestPoles = () => {
    const { Title, Text } = Typography
    const { id } = useParams()

    const { queryResult } = useShow<IEcoGestPole>({
        resource: 'EcoGestPole',
        id: id,
        meta: {
            operation: 'allEcoGestPoles', // the query operation
            fields: [
                {
                    edges: [
                        {
                            node: ['id', 'name', 'icon'],
                        },
                    ],
                },
            ],
        },
    })
    const { data, isLoading } = queryResult
    const record = data?.data

    return (
        <Show
            breadcrumb={null}
            isLoading={isLoading}
            title="Détail du Poste de consommation"
            headerProps={{
                extra: <RefreshButton onClick={() => queryResult.refetch()} />,
                subTitle: record?.name,
            }}
        >
            <Title level={5}>Titre</Title>
            <Text>{record?.name}</Text>
            <Title level={5}>{"Url de l'icône"}</Title>
            <Text>{record?.icon}</Text>
        </Show>
    )
}
