import React from 'react'
import { List, DateField } from '@refinedev/antd'
import { Divider, Table } from 'antd'
import { useTable } from '@refinedev/core'
import NrlinksInfosFilterForm from 'src/modules/NrlinksManagement/NrlinksInfos/NrlinksInfosFilterForm'
import {
    NrlinksInfos,
    nrlinksInfosFiltersFormValuesType,
} from 'src/modules/NrlinksManagement/NrlinksInfos/NrlinksInfos.d'

/**
 * This function returns a list of NrLINKS actve or inactive.
 *
 * @returns List of ActifsNrLINK.
 */
export const ListNrlinksInfos = () => {
    const tableProps = useTable<NrlinksInfos>({
        resource: 'nrlinks',
        dataProviderName: 'nrlinksDataProvider',
        meta: {
            operation: 'allNrlinks',
            fields: [
                {
                    fields: ['nrlinkGuid', 'firstReadingAt', 'lastReadingAt', 'consentCreatedAt'],
                },
                'totalCount',
            ],
        },
        filters: {
            initial: [
                {
                    field: 'isActiveNrlinks',
                    operator: 'eq',
                    value: true,
                },
            ],
        },
    })

    const data = tableProps.tableQueryResult?.data?.data ?? []

    /**
     * Update FilterTableNrlinksInfos when filter form.
     *
     * @param values NrlinksInfos Filters Form values.
     */
    const updateFilterTableNrlinksInfos = (values: nrlinksInfosFiltersFormValuesType) => {
        const isActiveNrlinks = values.isActiveNrlinks !== false
        tableProps.setFilters([
            {
                field: 'isActiveNrlinks',
                operator: 'eq',
                value: isActiveNrlinks,
            },
        ])
    }

    return (
        <>
            <NrlinksInfosFilterForm
                isLoadingInProgress={tableProps.tableQueryResult.isFetching}
                onSubmit={updateFilterTableNrlinksInfos}
            />
            <Divider style={{ userSelect: 'none' }} />
            <List headerProps={{ title: 'Informations nrLINK' }} breadcrumb={null}>
                <Table
                    loading={tableProps.tableQueryResult.isFetching}
                    dataSource={data}
                    pagination={{
                        pageSize: tableProps.pageSize,
                        current: tableProps.current,
                        total: tableProps.tableQueryResult.data?.total,
                        position: ['bottomRight'],
                        size: 'small',
                    }}
                    rowKey="nrlinkGuid"
                    onChange={(pagination, _filters, sorter, extra) => {
                        switch (extra.action) {
                            // Handle sorting
                            case 'sort':
                                // Handle only single column sorting
                                if (!Array.isArray(sorter))
                                    tableProps.setSorter([
                                        { field: `${sorter.field}`, order: sorter.order === 'ascend' ? 'asc' : 'desc' },
                                    ])
                                return
                            // Handle Pagination
                            case 'paginate':
                                // If page changed
                                if (tableProps.current !== pagination.current)
                                    tableProps.setCurrent(Number(pagination.current))
                                // If pageSize changed
                                if (tableProps.pageSize !== pagination.pageSize) {
                                    tableProps.setPageSize(Number(pagination.pageSize))
                                    tableProps.setCurrent(1)
                                }
                                return
                        }
                    }}
                >
                    <Table.Column dataIndex="nrlinkGuid" title="Guid NrLINK" sorter ellipsis />
                    <Table.Column
                        dataIndex="firstReadingAt"
                        title="Première remontée des données"
                        sorter
                        ellipsis
                        render={(value) => <DateField value={value} format="YYYY-MM-DD HH:mm:ss" />}
                    />
                    <Table.Column
                        dataIndex="lastReadingAt"
                        title="Dernière remontée des données"
                        sorter
                        ellipsis
                        render={(value) => <DateField value={value} format="YYYY-MM-DD HH:mm:ss" />}
                    />
                    <Table.Column
                        dataIndex="consentCreatedAt"
                        title="Date du consentement actif"
                        sorter
                        ellipsis
                        render={(value) => (value ? <DateField value={value} format="YYYY-MM-DD HH:mm:ss" /> : null)}
                    />
                </Table>
            </List>
        </>
    )
}
