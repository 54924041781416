import { useState } from 'react'
import { AuthBindings } from '@refinedev/core'

import { IdToken, useAuth0 } from '@auth0/auth0-react'

/**
 * Main application.
 *
 * @returns Void.
 */
export const useAuthProvider = () => {
    const { getIdTokenClaims, isLoading, loginWithRedirect, isAuthenticated, user, logout } = useAuth0()
    const [bearer, setBearer] = useState<string | null>(null)

    const authProvider: AuthBindings = {
        //eslint-disable-next-line
        login: async () => {
            loginWithRedirect()
            return { success: true }
        },
        //eslint-disable-next-line
        logout: async () => {
            localStorage.removeItem('token')
            setBearer(null)

            logout({ returnTo: window.location.origin })
            return { success: true }
        },
        //eslint-disable-next-line
        onError: async (error) => ({
            error,
        }),
        //eslint-disable-next-line
        check: async () => {
            // Set the localStorage token, so that it can be used on the App.tsx
            try {
                const token = await getIdTokenClaims()
                if (token) {
                    setBearer('bearer ' + localStorage.getItem('token'))
                    localStorage.setItem('token', token?.__raw)

                    return {
                        authenticated: true,
                    }
                } else
                    return {
                        authenticated: false,
                        redirectTo: '/login',
                        logout: true,
                    }
                //eslint-disable-next-line
            } catch (error: any) {
                return {
                    authenticated: false,
                    error: new Error(error),
                    redirectTo: '/login',
                    logout: true,
                }
            }
        },
        //eslint-disable-next-line
        getPermissions: async () => null,
        //eslint-disable-next-line
        getIdentity: async () => {
            if (user) {
                return {
                    ...user,
                    avatar: user.picture,
                }
            }
            return null
        },
    }

    getIdTokenClaims().then((token: IdToken | undefined) => {
        if (token) {
            setBearer('bearer ' + localStorage.getItem('token'))
            localStorage.setItem('token', token?.__raw)
        }
    })

    return { isLoading, authProvider, isAuthenticated, bearer }
}
