export {}

/**
 *
 */
declare global {
    /**
     * Extends window global variable.
     */
    interface Window {
        /**
         * Extends window to add _env_ varaible.
         */
        //eslint-disable-next-line
        _env_: any
    }
}

if (process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development') {
    for (const [key, value] of Object.entries(process.env)) {
        if (key.startsWith('REACT_APP_')) {
            window._env_[key] = value
        }
    }
}

/**
 * API Backend Url.
 */
export const API_URL = `${window._env_.REACT_APP_API_URL}/graphql`

/**
 * Auth0 Domain.
 */
export const AUTH0_DOMAIN = window._env_.REACT_APP_AUTH0_DOMAIN

/**
 * Auth0 Client id.
 */
export const AUTH0_CLIENT_ID = window._env_.REACT_APP_AUTH0_CLIENT_ID

/**
 * SupplyContracts Toolbar Menu Name, used to link submenus.
 */
export const SupplyContractsMenuConfigName = 'supplyContracts'

/**
 * Advices Toolbar Menu Name, used to link submenus.
 */
export const AdvicesMenuConfigName = 'advices'

/**
 * NrlinksManagement Toolbar Menu Name, used to link submenus.
 */
export const NrlinksManagementMenuConfigName = 'nrlinksManagement'

/**
 * Logistic Toolbar Menu Name, used to link submenus.
 */
export const LogisticMenuConfigName = 'logistic'

/**
 * News Toolbar Menu Name, used to link submenus.
 */
export const InformationMenuConfigName = 'information'
