import {
    NrlinkWidgetsResourceConfig,
    nrlinkWidgetsRoutes,
} from 'src/modules/NrlinksManagement/NrlinkWidgets/NrlinkWidgetsConfig'
import { NrlinksManagementMenuConfigName } from 'src/configs'
import {
    NrlinksInfosResourceConfig,
    nrlinksInfosRoutes,
} from 'src/modules/NrlinksManagement/NrlinksInfos/NrlinksConfig'
import { RouteProps } from 'react-router-dom'

/**
 * NrlinksManagementConfig.
 */
export const NrlinksManagementConfig = [
    {
        name: NrlinksManagementMenuConfigName,
        meta: {
            label: 'Gestion nrLINK',
        },
    },
    { ...NrlinkWidgetsResourceConfig },
    { ...NrlinksInfosResourceConfig },
]

/**
 * NrlinksManagement Routes.
 */
export const NrlinksManagementRoutes: RouteProps[] = [...nrlinkWidgetsRoutes, ...nrlinksInfosRoutes]
