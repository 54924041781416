import React, { useState } from 'react'
import { DeleteButton, EditButton, List, useTable, useSelect } from '@refinedev/antd'
import { Select, Space, Table } from 'antd'
import { ITariffComponent } from 'src/modules/SupplyContracts/TariffComponents/TariffComponents.d'
import { IContract } from '../Contracts/Contracts'
import { LabelInValueType, RawValueType } from 'src/modules/utils/commonTypes'
import { ITariffType } from '../TariffTypes/TariffTypes'

/**
 * This function returns a list of tariff components.
 *
 * @returns List of tariff components.
 */
export const ListTariffComponents = () => {
    const [contractId, setContractId] = useState<string | undefined>(undefined)
    const [tariffTypeId, setTariffTypeId] = useState<string | undefined>(undefined)

    const { selectProps: contractSelectProps } = useSelect<IContract>({
        resource: 'contract',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allContracts',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['name', 'id'],
                        },
                    ],
                },
            ],
        },
        onSearch:
            /**
             * Search input value for nrlink.
             *
             * @param inputValue IhdGuid Value of Nrlink.
             * @returns The nrlinks related to the search.
             */
            (inputValue) => [
                {
                    field: 'name',
                    operator: 'contains',
                    value: inputValue,
                },
            ],
    })

    const { selectProps: tariffTypeSelectProps } = useSelect<ITariffType>({
        resource: 'tariffType',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allTariffTypes',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['name', 'id'],
                        },
                    ],
                },
            ],
        },
        onSearch:
            /**
             * Search input value for nrlink.
             *
             * @param inputValue IhdGuid Value of Nrlink.
             * @returns The nrlinks related to the search.
             */
            (inputValue) => [
                {
                    field: 'name',
                    operator: 'contains',
                    value: inputValue,
                },
            ],
    })


    const { tableProps } = useTable<ITariffComponent>({
        meta: {
            operation: 'allTariffComponents',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'name',
                                'startTime',
                                'endTime',
                                {
                                    tariffType: ['name'],
                                },
                                {
                                    contract: ['name'],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        permanentFilter: [
            {
                field: 'contractId',
                operator: 'eq',
                value: contractId
            },
            {
                field: 'tariffTypeId',
                operator: 'eq',
                value: tariffTypeId
            }
        ],
        sorters: {
            initial: [
                {
                    field: 'id',
                    order: 'asc',
                },
            ],
        },
    })

    return (
        <div>
            <List breadcrumb={null}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginBottom: '15px'
                    }}
                >
                    <Select
                        {...tariffTypeSelectProps}
                        placeholder="Nom du type de tariff"
                        allowClear
                        style={{marginRight: "60px", width: "250px" }}
                        onSelect={(value: RawValueType | LabelInValueType) => {
                            typeof value === 'string' && setTariffTypeId(value)
                        }}
                        onClear={() => setTariffTypeId(undefined)}
                    />
                    <Select
                        {...contractSelectProps}
                        placeholder="Nom du contrat"
                        allowClear
                        style={{marginRight: "60px", width: "250px" }}
                        onSelect={(value: RawValueType | LabelInValueType) => {
                            typeof value === 'string' && setContractId(value)
                        }}
                        onClear={() => setContractId(undefined)}
                    />
                </div>
                <Table
                    {...tableProps}
                    pagination={{
                        ...tableProps.pagination,
                        position: ['bottomRight'],
                        size: 'small',
                    }}
                    rowKey="id"
                >
                    <Table.Column dataIndex="name" title="Nom de la composante" sorter />
                    <Table.Column dataIndex={['tariffType', 'name']} title="Type de Tarif" />
                    <Table.Column dataIndex={['contract', 'name']} title="Nom du Contrat" />
                    <Table.Column dataIndex="startTime" title="Heure Début" />
                    <Table.Column dataIndex="endTime" title="Heure Fin" />
                    <Table.Column<ITariffComponent>
                        title="Actions"
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton hideText size="small" recordItemId={record.id} />
                                <DeleteButton hideText size="small" recordItemId={record.id} />
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </div>
    )
}
