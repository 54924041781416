import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import { LogisticMenuConfigName } from 'src/configs'

import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'
import { ListNrlinks } from './ListNrlinks'

/**
 * Resource Nrlinks Stock Name.
 */
export const resourceNrlinksStockName = 'nrlink'

const nrlinksStockUrls = getResourceUrlsFromResourceName(resourceNrlinksStockName, LogisticMenuConfigName)

/**
 * NRLINK Stock Resource config.
 */
export const NrlinksStockResourceConfig: IRefineResourceConfig = {
    name: resourceNrlinksStockName,
    meta: {
        parent: LogisticMenuConfigName,
        label: 'Stock Nrlinks',
    },
    list: nrlinksStockUrls.list,
}

/**
 * NrlinksStockRoutes.
 */
export const nrlinksStockRoutes: RouteProps[] = [
    {
        path: nrlinksStockUrls.list,
        Component: ListNrlinks,
    },
]
