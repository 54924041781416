import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import { EditContracts, ListContracts, CreateContracts } from 'src/modules/SupplyContracts/Contracts'
import { SupplyContractsMenuConfigName } from 'src/configs'

import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'

/**
 * Resource Contracts Name.
 */
export const resourceContractsName = 'contract'

const contractsUrls = getResourceUrlsFromResourceName(resourceContractsName, SupplyContractsMenuConfigName)

/**
 * Contracts Resource config.
 */
export const ContractsResourceConfig: IRefineResourceConfig = {
    name: resourceContractsName,
    meta: {
        parent: SupplyContractsMenuConfigName,
        label: 'Contrats',
    },
    ...contractsUrls,
}

/**
 * ContractsRoutes.
 */
export const contractsRoutes: RouteProps[] = [
    {
        path: contractsUrls.list,
        Component: ListContracts,
    },
    {
        path: contractsUrls.create,
        Component: CreateContracts,
    },
    {
        path: contractsUrls.edit,
        Component: EditContracts,
    }
]
