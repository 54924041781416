import React from 'react'
import { RefreshButton, Show } from '@refinedev/antd'
import { Table, Tabs, Typography } from 'antd'
import { IContract, ITariff } from 'src/modules/SupplyContracts/Contracts/Contracts.d'
import { ITariffComponent } from 'src/modules/SupplyContracts/TariffComponents/TariffComponents.d'
import { useShow } from '@refinedev/core'
import _ from 'lodash'
import moment from 'moment'
import { useParams } from 'react-router-dom'

const { TabPane } = Tabs
/**
 * This function returns a view of a Contract details.
 *
 * @returns View to show details of a Contract.
 */
export const ShowContracts = () => {
    const { Title, Text } = Typography
    const { id } = useParams()

    const { queryResult } = useShow<IContract>({
        resource: 'contract',
        id: id,
        meta: {
            operation: 'allContracts', // the query operation
            fields: [
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'name',
                                {
                                    commercialOffer: ['name'],
                                },
                                {
                                    contractType: ['name'],
                                },
                                {
                                    tariffType: ['name'],
                                },
                                {
                                    tariffComponents: ['name'],
                                },
                                {
                                    contractPeriods: [
                                        'id',
                                        'startAt',
                                        'endAt',
                                        {
                                            tariffs: [
                                                'power',
                                                'price',
                                                {
                                                    tariffComponent: ['name'],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    })
    const { data, isLoading } = queryResult
    const record = data?.data

    /**
     * This Variable defines witch columns we will have in the tables.
     * A Column is the name of a tariff Component.
     * So we map through the tariff components related to the contract to know what to have.
     *
     * We will use this variable like a default value for all the tariffs.
     */
    const tariffsColumnsFormat: /**
     * We have key/value object witch correspond to tariffComponent/price.
     */
    {
        [key: string]: number
    } = {}
    // we initialise the columns
    record?.tariffComponents.map((tc: ITariffComponent) => (tariffsColumnsFormat[tc.name] = 0))

    /**
     * This function is to fill the tariff components of the tariffs we have.
     * The ones that we dont have are 0.
     * This will create all the variables so the table can read it.
     *
     * @param values Values of the tariffs we have.
     * @returns Values we have formated so that the table can read it.
     */
    const constructeTariffs = (
        /**
         * Values we want to format.
         */
        values: ITariff[],
    ) => {
        const newValues = tariffsColumnsFormat
        Object.keys(newValues).map((component: string) => (newValues[component] = 0))

        _.map(values, (value) => {
            newValues[value.tariffComponent.name] = value.price
        })

        return newValues
    }

    /**
     * A function that format the tariffs for the table to read.
     *
     * @param tariffs The Tariffs we want to format.
     * @returns Array of tariffs.
     */
    const formatDataTable = (
        /** The Tariffs that we want to convert to be able to read it in the table columns. */
        tariffs: ITariff[] | undefined,
    ) => {
        // if the
        if (tariffsColumnsFormat) {
            return _(tariffs)
                .groupBy('power')
                .map((values, key) => ({ power: key, ...constructeTariffs(values) }))
                .value()
        }

        return []
    }

    return (
        <Show
            breadcrumb={null}
            isLoading={isLoading}
            title="Détails du Contrat"
            headerProps={{
                extra: <RefreshButton onClick={() => queryResult.refetch()} />,
                subTitle: record?.name,
            }}
        >
            <Title level={5}>Nom du Contrat</Title>
            <Text>{record?.name}</Text>

            <Title level={5}>Type du Contrat</Title>
            <Text>{record?.contractType.name}</Text>

            <Title level={5}>Type du Tarif</Title>
            <Text>{record?.tariffType.name}</Text>

            <Title level={5}>Les Periodes du Contart et les tarifications</Title>

            <Tabs>
                {record?.contractPeriods.map((period) => (
                    <TabPane
                        tab={`${moment(period.startAt).format('DD/MM/YY')} => ${moment(period.endAt).format(
                            'DD/MM/YY',
                        )}`}
                        key={period.id}
                    >
                        <Table dataSource={formatDataTable(period.tariffs)} pagination={false}>
                            <Table.Column dataIndex="power" title="Puissance (kva)" key={0} />

                            {Object.keys(tariffsColumnsFormat)?.map((tariffComponent, index) => (
                                <Table.Column
                                    key={index + 1} // the key = 0 is for power
                                    dataIndex={`${tariffComponent}`}
                                    title={`${tariffComponent} (€)`}
                                />
                            ))}
                        </Table>
                    </TabPane>
                ))}
            </Tabs>
        </Show>
    )
}
