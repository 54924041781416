/**
 * Days with there values in the API.
 */
export const daysOptions = [
    {
        value: 'SU',
        label: 'Dimanche',
    },
    {
        value: 'MO',
        label: 'Lundi',
    },
    {
        value: 'TU',
        label: 'Mardi',
    },
    {
        value: 'WE',
        label: 'Mercredi',
    },
    {
        value: 'TH',
        label: 'Jeudi',
    },
    {
        value: 'FR',
        label: 'Vendredi',
    },
    {
        value: 'SA',
        label: 'Samedi',
    },
]
