import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import {
    EditContractTypes,
    ListContractTypes,
    CreateContractTypes,
} from 'src/modules/SupplyContracts/ContractTypes'
import { SupplyContractsMenuConfigName } from 'src/configs'

import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'

/**
 * Resource ContractTypes Name.
 */
export const resourceContractTypesName = 'contractType'

const contractTypesUrls = getResourceUrlsFromResourceName(resourceContractTypesName, SupplyContractsMenuConfigName)

/**
 * ContractTypes Resource config.
 */
export const ContractTypesResourceConfig: IRefineResourceConfig = {
    name: resourceContractTypesName,
    meta: {
        parent: SupplyContractsMenuConfigName,
        label: 'Type de Contrats',
    },
    ...contractTypesUrls,
}

/**
 * ContractTypesRoutes.
 */
export const contractTypesRoutes: RouteProps[] = [
    {
        path: contractTypesUrls.list,
        Component: ListContractTypes,
    },
    {
        path: contractTypesUrls.create,
        Component: CreateContractTypes,
    },
    {
        path: contractTypesUrls.edit,
        Component: EditContractTypes,
    }
]
