import React from 'react'
import { useForm } from '@refinedev/antd'
import { Form, Select, Button } from 'antd'
import { NrlinksInfosFilterFormProps } from 'src/modules/NrlinksManagement/NrlinksInfos/NrlinksInfos.d'

/**
 * NrlinksInfosFilterForm Component.
 *
 * @param props N/A.
 * @param props.isLoadingInProgress When form is Loading.
 * @param props.onSubmit On Submit handler.
 * @returns NrlinksInfosFilterForm Component.
 */
const NrlinksInfosFilterForm = ({ isLoadingInProgress, onSubmit }: NrlinksInfosFilterFormProps) => {
    const { formProps, saveButtonProps } = useForm()

    return (
        <Form
            {...formProps}
            initialValues={{
                isActiveNrlinks: true,
            }}
            layout="inline"
            onFinish={onSubmit}
            style={{ display: 'flex', gap: '16px' }}
        >
            <Form.Item label="Status Nrlink" name="isActiveNrlinks">
                <Select
                    style={{ minWidth: '150px' }}
                    options={[
                        { label: 'Actif', value: true },
                        { label: 'Inactif', value: false },
                    ]}
                />
            </Form.Item>
            <div style={{ display: 'flex', gap: '16px' }}>
                <Button onClick={() => formProps.form?.resetFields()}>Annuler</Button>
                <Button {...saveButtonProps} type="primary" loading={isLoadingInProgress}>
                    Filtrer
                </Button>
            </div>
        </Form>
    )
}

export default NrlinksInfosFilterForm
