import { List, useTable } from '@refinedev/antd'
import { Button, Input, Space, Table } from 'antd'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { INrlink } from '../ChameleonDeliveries/ChameleonDeliveries'

/**
 * List of all nrlinks available in stock with store name and url admin.
 * 
 * @returns JSX Element.
 */
export const ListNrlinks = () => {

    const [nrlinkGuid, setNrlinkGuid] = useState<string | undefined>(undefined)

    const { tableProps, tableQueryResult, setCurrent } = useTable<INrlink>({
        dataProviderName: 'logisticDataProvider',
        meta: {
            operation: 'allNrlinks',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'ihdGuid',
                                {
                                    store: [ 'name', 'adminUrl' ],
                                },
                            ]
                        },
                    ],
                },
            ],
        },
        sorters: {
            initial: [
                {
                    field: 'id',
                    order: 'asc',
                },
            ],
        },
        filters: {
            permanent: [
                {
                    field: "ihdGuid",
                    operator: "contains",
                    value: nrlinkGuid
                }
            ]
        }
    })


    return (
        <List breadcrumb={null}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    marginBottom: '15px'
                }}
            >
                <Input
                    value={nrlinkGuid}
                    placeholder='Nrlink Guid'
                    style={{ width: "150px" }}
                    onChange={(input) => {
                        input.target.value ? setNrlinkGuid(input.target.value) : setNrlinkGuid(undefined)
                        setCurrent(1)
                        tableQueryResult.refetch()
                    }}
                />
            </div>
            <Table
                {...tableProps}
                pagination={{
                    ...tableProps.pagination,
                    position: ['bottomRight'],
                    size: 'small',
                }}
                rowKey="id"
            >
                <Table.Column dataIndex="ihdGuid" title="Nrlink Guid" />
                <Table.Column dataIndex={['store', 'name']} title="Store Associé" />
                <Table.Column<INrlink>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            {
                                record.store?.adminUrl && (
                                    <Button size="small" >
                                        <Link to={`${record.store?.adminUrl}`} target="_blank">
                                            Ouvrir Admin du Store
                                        </Link>
                                    </Button>
                                )
                            }
                        </Space>
                    )}
                />
            </Table>
        </List>
    )
}
