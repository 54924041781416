import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import { EditProviders, ListProviders, CreateProvider } from 'src/modules/SupplyContracts/Providers'
import { SupplyContractsMenuConfigName } from 'src/configs'
import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'

/**
 * Resource Provider Name.
 */
export const resourceProviderName = 'provider'

const providerUrls = getResourceUrlsFromResourceName(resourceProviderName, SupplyContractsMenuConfigName)
/**
 * Provider Resource config.
 */
export const providerResourceConfig: IRefineResourceConfig = {
    name: resourceProviderName,
    meta: {
        parent: SupplyContractsMenuConfigName,
        label: 'Fournisseurs',
    },

    ...providerUrls,
}

/**
 * ProviderRoutes.
 */
export const providerRoutes: RouteProps[] = [
    {
        path: providerUrls.list,
        Component: ListProviders,
    },
    {
        path: providerUrls.create,
        Component: CreateProvider,
    },
    {
        path: providerUrls.edit,
        Component: EditProviders,
    }
]
