import React, { Dispatch, SetStateAction, useState } from 'react'
import { CreateButton, DeleteButton, EditButton, List, useForm, useSelect, useTable } from '@refinedev/antd'
import { Button, Form, Modal, Select, Space, Table } from 'antd'
import { IStore, ResponseTransfertPackage } from 'src/modules/Logistics/Stores/Stores.d'
import { OpenNotificationFunctionType, LabelInValueType, RawValueType } from 'src/modules/utils/commonTypes.d'
import { SelectOutlined } from '@ant-design/icons'
import { ICarton, INrlink, IPallet } from 'src/modules/Logistics/ChameleonDeliveries/ChameleonDeliveries.d'
import { useCustom, useNotification } from '@refinedev/core'
import { API_URL } from 'src/configs'

/**
 * This function returns a list of stores.
 *
 * @returns List of stores.
 */
export const ListStores = () => {
    const { tableProps } = useTable<IStore>({
        dataProviderName: 'logisticDataProvider',
        meta: {
            operation: 'allStores',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name', 'location', 'totalPallets', 'totalCartons', 'totalNrlinks'],
                        },
                    ],
                },
            ],
        },
        sorters: {
            initial: [
                {
                    field: 'id',
                    order: 'asc',
                },
            ],
        },
    })

    const { open } = useNotification()
    const { formProps, form } = useForm()

    // these variables are for showing packages
    const [isShowPackagesModalVisible, setIsShowPackagesModalVisible] = useState(false)
    const [storeToShowPackages, setStoreToShowPackages] = useState<null | string>(null)

    // these variables are for transfert packages
    const [isTransfertPackageModalVisible, setIsTransfertPackageModalVisible] = useState(false)
    const [packageType, setPackageType] = useState('nrlink')
    const [packageTypeToShow, setPackageTypeToShow] = useState('nrlink')
    const [packageId, setPackageId] = useState<string>('')
    const [destinationStoreId, setDestinationStoreId] = useState('')

    const { selectProps: storeSelectProps } = useSelect<IStore>({
        resource: 'store',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        dataProviderName: 'logisticDataProvider',
        meta: {
            operation: 'allStores',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['name', 'id'],
                        },
                    ],
                },
            ],
        },
    })

    /**
     * Function that opens a notification based on params.
     *
     * @param message Message to Pop.
     * @param type Type of notification.
     * @param key Key.
     */
    const openNotification: OpenNotificationFunctionType = (message: string, type: 'success' | 'error' | 'progress', key: string) => {
        // eslint-disable-next-line
        open!({
            message,
            type,
            key,
        })
    }

    const { refetch: transfertPackage, isFetching: isPackageTransfering } = useCustom<ResponseTransfertPackage>({
        url: `${API_URL}/logistic`,
        dataProviderName: 'logisticDataProvider',
        config: {
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token'),
            },
        },
        method: 'post',
        queryOptions: {
            enabled: false,
            retry: false,
            //eslint-disable-next-line
            onError(err) {
                openNotification(`${err.message.split(':')[0]}`, 'error', 'transfert-package-fail')
            },
            //eslint-disable-next-line
            onSuccess(data) {
                openNotification(`${packageType} transféré avec succès !`, 'success', 'transfert-package-success')
            },
        },
        meta: {
            operation: 'movePackageToStore',
            fields: ['status'],
            variables: {
                inputFields: {
                    value: { packageId: packageId, destinationStoreId: destinationStoreId },
                    type: 'MovePackageToStoreFields!',
                },
            },
        },
        successNotification: {
            message: `${packageType} transféré avec succès !`,
            type: 'success',
            key: 'transfert-package-success',
        },
        errorNotification: {
            message: `Erreur lors du transfert du ${packageType}`,
            type: 'error',
            key: 'transfert-package-error',
        },
    })

    /**
     * Function that clears the form content.
     */
    const clearForm = () => {
        form.resetFields()
        setPackageId('')
        setDestinationStoreId('')
    }

    /**
     * Open Modal to show packages.
     *
     * @param recordId StoreId that we want to filter his packages.
     */
    const openModalThatShowPackages = (recordId: string) => {
        setStoreToShowPackages(recordId)
        setIsShowPackagesModalVisible(true)
    }

    return (
        <>
            <List
                headerProps={{
                    extra: (
                        <>
                            <Button
                                type="primary"
                                icon={<SelectOutlined />}
                                onClick={() => setIsTransfertPackageModalVisible(true)}
                            >
                                Transférer un package
                            </Button>
                            <CreateButton>Nouveau store</CreateButton>
                        </>
                    ),
                }}
                breadcrumb={null}
            >
                <Table
                    {...tableProps}
                    pagination={{
                        ...tableProps.pagination,
                        position: ['bottomRight'],
                        size: 'small',
                    }}
                    rowKey="id"
                >
                    <Table.Column dataIndex="location" title="Location du store" />
                    <Table.Column dataIndex="name" title="Nom" />
                    <Table.Column dataIndex="totalNrlinks" title="total des nrlinks" />
                    <Table.Column dataIndex="totalCartons" title="total des cartons" />
                    <Table.Column dataIndex="totalPallets" title="total des pallets" />
                    <Table.Column<IStore>
                        title="Actions"
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton hideText size="small" recordItemId={record.id} />
                                <DeleteButton
                                    hideText
                                    size="small"
                                    recordItemId={record.id}
                                    dataProviderName="logisticDataProvider"
                                />
                                <Button type="primary" onClick={() => openModalThatShowPackages(record.id)}>
                                    Packages Associés
                                </Button>
                            </Space>
                        )}
                    />
                </Table>
            </List>

            {/** Modal to show nrlinks for a specific store. */}

            <Modal
                visible={isShowPackagesModalVisible}
                onCancel={() => {
                    setIsShowPackagesModalVisible(false)
                }}
                footer={null}
            >
                <Select
                    value={packageTypeToShow}
                    onChange={(type) => {
                        setPackageTypeToShow(type)
                    }}
                    options={[
                        { value: 'nrlink', label: 'Nrlink' },
                        { value: 'carton', label: 'Carton' },
                        { value: 'pallet', label: 'Pallet' },
                    ]}
                />
                { packageTypeToShow === 'nrlink' && <ShowNrlinksTable openNotification={openNotification} storeId={storeToShowPackages} /> }
                { packageTypeToShow === 'carton' && <ShowCartonsTable openNotification={openNotification} storeId={storeToShowPackages} /> }
                { packageTypeToShow === 'pallet' && <ShowPalletsTable openNotification={openNotification} storeId={storeToShowPackages} /> } 
            </Modal>

            {/* Modal to transfert package */}

            <Modal
                open={isTransfertPackageModalVisible}
                onCancel={() => {
                    clearForm()
                    setIsTransfertPackageModalVisible(false)
                }}
                footer={null}
            >
                <Form
                    layout="vertical"
                    {...formProps}
                    onFinish={async (values) => {
                        await transfertPackage()
                        clearForm()
                    }}
                >
                    <Select
                        value={packageType}
                        onChange={(type) => {
                            clearForm()
                            setPackageType(type)
                        }}
                        options={[
                            { value: 'nrlink', label: 'Nrlink' },
                            { value: 'carton', label: 'Carton' },
                            { value: 'pallet', label: 'Pallet' },
                        ]}
                    />
                    <Form.Item
                        label={`Selectionnez l'identifiant de votre ${packageType}: `}
                        name="packageId"
                    >
                        {
                            (packageType === "nrlink") && <NrlinkSelect setPackageId={setPackageId} />
                        }
                        {
                            (packageType === "carton") && <CartonSelect setPackageId={setPackageId} />
                        }
                        {
                            (packageType === "pallet") && <PalletSelect setPackageId={setPackageId} />
                        }
                    </Form.Item>
                    <Form.Item
                        label={`Selectionnez le store destination :`}
                        name="destinationStoreId"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            {...storeSelectProps}
                            allowClear
                            onSelect={(value: RawValueType | LabelInValueType) => {
                                typeof value === 'string' && setDestinationStoreId(value)
                            }}
                        />
                    </Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={isPackageTransfering}
                        loading={isPackageTransfering}
                    >
                        Sauvegarder
                    </Button>
                </Form>
            </Modal>
        </>
    )
}


/**
 * Table to show nrlinks.
 *
 * @param props Props.
 * @param props.storeId Store Id that we need to show package for.
 * @param props.openNotification Open notification function. 
 * @returns Jsx Element.
 */
const ShowNrlinksTable = ({
    /**
     * Store id.
     */
    storeId,
    /**
     * Open Notification function.
     */
    openNotification
}: /**
*/
{
    /**
     * Store id.
     */
    storeId: string | null
    /**
     * Open notification function.
     */
    openNotification: OpenNotificationFunctionType
}) => {

    const { tableProps: nrlinkTableProps } = useTable<INrlink>({
        dataProviderName: 'logisticDataProvider',
        resource: 'nrlink',
        queryOptions: {
            retry: false,
            //eslint-disable-next-line
            onError(err) {
                openNotification(`${err.message.split(':')[0]}`, 'error', 'get-nrlinks-for-store-fail')
            },
        },
        meta: {
            operation: 'allNrlinks',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'ihdGuid',
                                {
                                    carton: ['identifier', { pallet: ['identifier'] }],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        errorNotification: {
            message: 'Erreur lors du chargement des nrlinks liée au store',
            type: 'error',
            key: 'get-nrlinks-for-store-error',
        },
        permanentFilter: [
            {
                field: 'storeId',
                operator: 'eq',
                value: storeId,
            },
        ],
    })

    return (
        <Table
            {...nrlinkTableProps}
            pagination={{
                ...nrlinkTableProps.pagination,
                position: ['bottomRight'],
                size: 'small',
            }}
            rowKey="id"
        >
            <Table.Column dataIndex="ihdGuid" title="IHD Guid" />
            <Table.Column dataIndex={['carton', 'identifier']} title="Identifiant carton" />
            <Table.Column dataIndex={['carton', 'pallet', 'identifier']} title="Identifiant pallet" />
        </Table>
    )
}



/**
 * Table to show cartons.
 *
 * @param props Props.
 * @param props.storeId Store Id that we need to show package for.
 * @param props.openNotification Open notification function. 
 * @returns Jsx Element.
 */
const ShowCartonsTable = ({
    /**
     * Store id.
     */
    storeId,
    /**
     * Open Notification function.
     */
    openNotification
}: /**
*/
{
    /**
     * Store id.
     */
    storeId: string | null
    /**
     * Open notification function.
     */
    openNotification: OpenNotificationFunctionType
}) => {

    const { tableProps: cartonTableProps} = useTable<ICarton>({
    dataProviderName: 'logisticDataProvider',
    resource: 'carton',
    queryOptions: {
        retry: false,
        //eslint-disable-next-line
        onError(err) {
            openNotification(`${err.message.split(':')[0]}`, 'error', 'get-cartons-for-store-fail')
        },
    },
    meta: {
        operation: 'allCartons',
        fields: [
            'totalCount',
            {
                edges: [
                    {
                        node: [
                            'id',
                            'identifier',
                            {
                                pallet: ['identifier'],
                            },
                        ],
                    },
                ],
            },
        ],
    },
    errorNotification: {
        message: 'Erreur lors du chargement des cartons liée au store',
        type: 'error',
        key: 'get-cartons-for-store-error',
    },
    permanentFilter: [
        {
            field: 'storeId',
            operator: 'eq',
            value: storeId,
        },
    ],
})

    return (
        <Table
            {...cartonTableProps}
            pagination={{
                ...cartonTableProps.pagination,
                position: ['bottomRight'],
                size: 'small',
            }}
            rowKey="id"
        >
            <Table.Column dataIndex="identifier" title="IHD Guid" />
            <Table.Column dataIndex={['pallet', 'identifier']} title="Identifiant pallet" />
        </Table>
    )
}



/**
 * Table to show pallets.
 *
 * @param props Props.
 * @param props.storeId Store Id that we need to show package for.
 * @param props.openNotification Open notification function. 
 * @returns Jsx Element.
 */
const ShowPalletsTable = ({
    /**
     * Store id.
     */
    storeId,
    /**
     * Open Notification function.
     */
    openNotification
}: /**
*/
{
    /**
     * Store id.
     */
    storeId: string | null
    /**
     * Open notification function.
     */
    openNotification: OpenNotificationFunctionType
}) => {

    const { tableProps: palletTableProps } = useTable<IPallet>({
    dataProviderName: 'logisticDataProvider',
    resource: 'pallet',
    queryOptions: {
        retry: false,
        //eslint-disable-next-line
        onError(err) {
            openNotification(`${err.message.split(':')[0]}`, 'error', 'get-pallets-for-store-fail')
        },
    },
    meta: {
        operation: 'allPallets',
        fields: [
            'totalCount',
            {
                edges: [
                    {
                        node: [
                            'id',
                            'identifier'
                        ],
                    },
                ],
            },
        ],
    },
    errorNotification: {
        message: 'Erreur lors du chargement des pallets liée au store',
        type: 'error',
        key: 'get-pallets-for-store-error',
    },
    permanentFilter: [
        {
            field: 'storeId',
            operator: 'eq',
            value: storeId,
        },
    ],
})

    return (
        <Table
            {...palletTableProps}
            pagination={{
                ...palletTableProps.pagination,
                position: ['bottomRight'],
                size: 'small',
            }}
            rowKey="id"
        >
            <Table.Column dataIndex="identifier" title="IHD Guid" />
        </Table>
    )
}


/**
 * Element that returns Select for nrlink package.
 *
 * @param props Props.
 * @param props.setPackageId Setter to set package Id once Selected. 
 * @returns Jsx Element.
 */
const NrlinkSelect = ({
    setPackageId
} : /**
    * Props Select.
     */
{ 
    /**
     * Set PackageId once selected.
     */
    setPackageId : Dispatch<SetStateAction<string>>
}) => {
    const { selectProps: nrlinkSelectProps } = useSelect<INrlink>({
        resource: 'nrlink',
        optionLabel: 'ihdGuid',
        optionValue: 'id',
        fetchSize: 100,
        dataProviderName: 'logisticDataProvider',
        meta: {
            operation: 'allNrlinks',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['ihdGuid', 'id'],
                        },
                    ],
                },
            ],
        },
        onSearch:
            /**
             * Search input value for nrlink.
             *
             * @param inputValue IhdGuid Value of Nrlink.
             * @returns The nrlinks related to the search.
             */
            (inputValue) => [
                {
                    field: 'ihdGuid',
                    operator: 'contains',
                    value: inputValue,
                },
            ],
    })

    return (
        <Select
            {...nrlinkSelectProps}
            allowClear
            onSelect={(value: RawValueType | LabelInValueType) => {
                typeof value === 'string' && setPackageId(value)
            }}
        />
    )
}

/**
 * Element that returns Select for carton package.
 *
 * @param props Props.
 * @param props.setPackageId Setter to set package Id once Selected. 
 * @returns Jsx Element.
 */
const CartonSelect = ({
    setPackageId
} : /**
    * Props Select.
     */
{ 
    /**
     * Set PackageId once selected.
     */
    setPackageId : Dispatch<SetStateAction<string>>
}) => {
    const { selectProps: cartonSelectProps } = useSelect<ICarton>({
        resource: 'carton',
        optionLabel: 'identifier',
        optionValue: 'id',
        fetchSize: 100,
        dataProviderName: 'logisticDataProvider',
        meta: {
            operation: 'allCartons',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['identifier', 'id'],
                        },
                    ],
                },
            ],
        },
        onSearch:
            /**
             * Search input value for carton.
             *
             * @param inputValue Identifier Value of carton.
             * @returns The cartons related to the search.
             */
            (inputValue) => [
                {
                    field: 'identifier',
                    operator: 'contains',
                    value: inputValue,
                },
            ],
    })

    return (
        <Select
            {...cartonSelectProps}
            allowClear
            onSelect={(value: RawValueType | LabelInValueType) => {
                typeof value === 'string' && setPackageId(value)
            }}
        />
    )
}


/**
 * Element that returns Select for pallet package.
 *
 * @param props Props.
 * @param props.setPackageId Setter to set package Id once Selected. 
 * @returns Jsx Element.
 */
const PalletSelect = ({
    setPackageId
} : /**
    * Props Select.
     */
{ 
    /**
     * Set PackageId once selected.
     */
    setPackageId : Dispatch<SetStateAction<string>>
}) => {
    const { selectProps: palletSelectProps } = useSelect<IPallet>({
        resource: 'pallet',
        optionLabel: 'identifier',
        optionValue: 'id',
        fetchSize: 100,
        dataProviderName: 'logisticDataProvider',
        meta: {
            operation: 'allPallets',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['identifier', 'id'],
                        },
                    ],
                },
            ],
        },
        onSearch:
            /**
             * Search input value for pallet.
             *
             * @param inputValue Identifier Value of pallet.
             * @returns The pallets related to the search.
             */
            (inputValue) => [
                {
                    field: 'identifier',
                    operator: 'contains',
                    value: inputValue,
                },
            ],
    })

    return (
        <Select
            {...palletSelectProps}
            allowClear
            onSelect={(value: RawValueType | LabelInValueType) => {
                typeof value === 'string' && setPackageId(value)
            }}
        />
    )

}