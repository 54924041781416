import { AdvicesMenuConfigName } from 'src/configs'
import { RouteProps } from 'react-router-dom'
import { NewsResourceConfig, newsRoutes } from 'src/modules/Information/News/NewsConfig'

/**
 * Information config.
 */
export const InformationConfig = [
    {
        name: AdvicesMenuConfigName,
        meta: {
            label: 'Information',
        },
    },
    { ...NewsResourceConfig },
]

/**
 * Advices Routes.
 */
export const InformationRoutes: RouteProps[] = [...newsRoutes]
