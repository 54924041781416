import React from 'react'
import { Refine, Authenticated } from '@refinedev/core'
import { notificationProvider, RefineThemes, ThemedLayoutV2, ErrorComponent } from '@refinedev/antd'
import { Outlet, BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import '@refinedev/antd/dist/reset.css'
import routerProvider, { NavigateToResource, CatchAllNavigate } from '@refinedev/react-router-v6'
import { Login } from 'src/pages/login'
import { useAuthProvider } from 'src/configs/authProvider'
import { getDataProviders } from 'src/configs/dataProviders'
import { resources } from 'src/configs/resources'
import { ConfigProvider, Avatar } from 'antd'
import { resourceProviderName } from 'src/modules/SupplyContracts/Providers/ProviderConfig'
import { routes } from 'src/configs/routes'

/**
 * Main application.
 *
 * @returns Void.
 */
function App() {
    const { bearer, authProvider, isLoading } = useAuthProvider()
    if (isLoading) {
        return <span>Loading...</span>
    }

    return (
        <Router>
            <ConfigProvider theme={RefineThemes.Green}>
                <Refine
                    notificationProvider={notificationProvider}
                    dataProvider={{ ...getDataProviders(`${bearer}`) }}
                    authProvider={authProvider}
                    routerProvider={routerProvider}
                    resources={resources}
                    options={{
                        warnWhenUnsavedChanges: true,
                    }}
                >
                    <Routes>
                        <Route
                            element={
                                <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                                    <ThemedLayoutV2
                                        Title={() => (
                                            <Link
                                                to="/"
                                                style={{
                                                    display: 'block',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Avatar src="/assets/images/myem.svg" alt="Myem" />
                                            </Link>
                                        )}
                                    >
                                        <Outlet />
                                    </ThemedLayoutV2>
                                </Authenticated>
                            }
                        >
                            <Route index element={<NavigateToResource resource={resourceProviderName} />} />
                            {routes.map((route) => (
                                <Route {...route} key={route.path} />
                            ))}
                        </Route>
                        <Route
                            element={
                                <Authenticated fallback={<Outlet />}>
                                    <NavigateToResource />
                                </Authenticated>
                            }
                        >
                            <Route path="/login" element={<Login />} />
                        </Route>
                        <Route path="*" element={<ErrorComponent />} />
                    </Routes>
                </Refine>
            </ConfigProvider>
        </Router>
    )
}

export default App
