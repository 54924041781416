import React from 'react'
import { Create, useForm } from '@refinedev/antd'
import { Form, Input } from 'antd'

import { IEcoGestRoom } from 'src/modules/Advices/EcoGestRooms/EcoGestRooms.d'

/**
 * This function returns a view to create one EcoGestRooms.
 *
 * @returns View to create one EcoGestRooms.
 */
export const CreateEcoGestRooms = () => {
    const { formProps, saveButtonProps } = useForm<IEcoGestRoom>()

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Titre" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="Url de l'icône" name="icon">
                    <Input />
                </Form.Item>
            </Form>
        </Create>
    )
}
