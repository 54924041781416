import React from 'react'
import { Create, useForm } from '@refinedev/antd'
import { Form, Input } from 'antd'

import { IContractType } from 'src/modules/SupplyContracts/ContractTypes/ContractTypes.d'

/**
 * This function returns a view to create a Contract Type.
 *
 * @returns View to create a ContractType.
 */
export const CreateContractTypes = () => {
    const { formProps, saveButtonProps } = useForm<IContractType>()

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Type de contrat"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Create>
    )
}
