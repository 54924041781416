/**
 * Energies.
 */
export const energies = [
    {
        value: 'heater',
        label: 'Chauffage',
    },
    {
        value: 'hotplate',
        label: 'Plaque de cuisson',
    },
    {
        value: 'sanitary',
        label: 'Sanitaire',
    },
]

/**
 * Equipments.
 */
export const equipments = [
    {
        value: 'television',
        label: 'Télévision',
    },
    {
        value: 'vacum',
        label: 'Aspirateur',
    },
    {
        value: 'oven',
        label: 'Four',
    },
    {
        value: 'microwave',
        label: 'Micro-ondes',
    },
    {
        value: 'fridge',
        label: 'Réfrigérateur',
    },
    {
        value: 'dishwasher',
        label: 'Lave-vaisselle',
    },
    {
        value: 'washingmachine',
        label: 'Machine à laver',
    },
    {
        value: 'dryver',
        label: 'Sèche-linge',
    },
    {
        value: 'laptop',
        label: 'Ordinateur portable',
    },
    {
        value: 'desktopcomputer',
        label: 'Ordinateur de bureau',
    },
    {
        value: 'freezer',
        label: 'Congélateur',
    },
    {
        value: 'kettle',
        label: 'Bouilloire',
    },
    {
        value: 'coffee_machine',
        label: 'Machine à café',
    },
    {
        value: 'swimmingpool',
        label: 'Piscine',
    },
    {
        value: 'heatpump',
        label: 'Pompe à chaleur',
    },
    {
        value: 'reversible_heatpump',
        label: 'Pompe à chaleur réversible',
    },
    {
        value: 'swimmingpool_heatpump',
        label: 'Pompe à chaleur de piscine',
    },
    {
        value: 'electric_car',
        label: 'Voiture électrique',
    },
    {
        value: 'aquarium',
        label: 'Aquarium',
    },
    {
        value: 'ceramic_hob',
        label: 'Plaque à induction',
    },
    {
        value: 'iron_plate',
        label: 'Plaque de fer',
    },
    {
        value: 'induction_plate',
        label: 'Plaque à induction',
    },
    {
        value: 'radiator',
        label: 'Radiateur',
    },
    {
        value: 'air_conditioner',
        label: 'Climatiseur',
    },
    {
        value: 'dry_towel',
        label: 'Sèche-serviette',
    },
    {
        value: 'water_heater',
        label: 'Chauffe-eau',
    },
]

/**
 * Home configuration.
 */
export const homeConfiguration = [
    {
        value: 'house',
        label: 'Maison',
    },
    {
        value: 'apartment',
        label: 'Appartement',
    },
]
