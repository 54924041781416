import { CrudFilters, CrudSorting, DataProvider, LogicalFilter, ConditionalFilter } from '@refinedev/core'
import { GraphQLClient } from 'graphql-request'
import * as gql from 'gql-query-builder'
import pluralize from 'pluralize'
import camelCase from 'camelcase'
import _ from 'lodash'

/**
 * This function generate sorting.
 *
 * @param sort Sort parameter.
 * @returns Void.
 */
const genereteSort = (sort?: CrudSorting) => {
    if (sort && sort.length > 0) {
        const sortQuery = sort.map((i) => {
            return `${i.field}_${i.order}`
        })

        return sortQuery.join().toUpperCase()
    }

    return []
}

/**
 * This function returns query filters.
 *
 * @param filters Filters params.
 * @returns Query filters.
 */
const generateFilter = (filters?: CrudFilters) => {
    //eslint-disable-next-line
    const queryFilters: { [key: string]: any } = {}

    if (filters) {
        filters.map((filter) => {
            if (filter.operator !== 'or') {
                const { field, operator, value } = filter as LogicalFilter

                if (operator === 'eq') {
                    queryFilters[`${field}`] = value
                } else {
                    queryFilters[`${field}${operator.charAt(0).toUpperCase() + operator.slice(1)}`] = value
                }
            } else {
                const { value } = filter as ConditionalFilter

                //eslint-disable-next-line
                const orFilters: any[] = []
                value.map((val) => {
                    const { field, operator, value } = val as LogicalFilter

                    orFilters.push({
                        [`${field}_${operator}`]: value,
                    })
                    return null
                })

                queryFilters['_or'] = orFilters
            }
            return null
        })
    }

    return queryFilters
}

/**
 * This function returns a custom data provider.
 *
 * @param client Graphql Client.
 * @returns DataProvider functions.
 */
const customDataProvider = (client: GraphQLClient): DataProvider => {
    return {
        //eslint-disable-next-line
        getList: async ({ resource, pagination, sorters, filters, meta }) => {
            const current = pagination?.current || 1
            const pageSize = pagination?.pageSize || 10

            const sortBy = genereteSort(sorters)
            const filterBy = generateFilter(filters)

            const camelResource = camelCase(resource)
            const resourceCapitalize = camelResource.charAt(0).toUpperCase() + camelResource.slice(1)

            const operation = meta?.operation ?? camelResource

            const { query, variables } = gql.query({
                operation,
                variables: {
                    start: (current - 1) * pageSize,
                    limit: pageSize,
                    ...meta?.variables,
                    sort: { value: sortBy, type: `[${resourceCapitalize}TypeSortEnum]` },
                    where: { value: filterBy, type: `Use${resourceCapitalize}Filter` },
                },
                fields: meta?.fields,
            })
            const response = await client.request(query, variables)

            return {
                data:
                    response[operation].fields ||
                    _.values(_.chain(response[operation].edges).mapValues('node').value()),
                total: response[operation].totalCount,
            }
        },

        //eslint-disable-next-line
        getMany: async ({ resource, ids, meta }) => {
            const camelResource = camelCase(resource)

            const operation = meta?.operation ?? camelResource

            const { query, variables } = gql.query({
                operation,
                variables: {
                    where: {
                        value: { id_in: ids },
                        type: 'JSON',
                    },
                },
                fields: meta?.fields,
            })

            const response = await client.request(query, variables)

            return {
                data: response[operation],
            }
        },

        //eslint-disable-next-line
        create: async ({ resource, variables, meta }) => {
            const singularResource = pluralize.singular(resource)
            const camelCreateName = camelCase(`create-${singularResource}`)

            // capitalize the first letter to use it in the type of variable
            const srCapitalizedFirstLetter = singularResource.charAt(0).toUpperCase() + singularResource.slice(1)

            const operation = meta?.operation ?? camelCreateName

            const { query, variables: gqlVariables } = gql.mutation({
                operation,
                variables: {
                    inputFields: {
                        value: variables,
                        type: `Create${srCapitalizedFirstLetter}Fields!`,
                    },
                },
                fields: meta?.fields ?? [
                    // fields to get back from the query
                    {
                        operation: singularResource,
                        fields: ['id'],
                        variables: {},
                    },
                ],
            })
            const response = await client.request(query, gqlVariables)

            return {
                data: response[operation][singularResource],
            }
        },

        //eslint-disable-next-line
        createMany: async ({ resource, variables, meta }) => {
            const singularResource = pluralize.singular(resource)
            const camelCreateName = camelCase(`create-${singularResource}`)

            const operation = meta?.operation ?? camelCreateName

            const response = await Promise.all(
                variables.map(async (param) => {
                    const { query, variables: gqlVariables } = gql.mutation({
                        operation,
                        variables: {
                            input: {
                                value: { data: param },
                                type: `${camelCreateName}Input`,
                            },
                        },
                        fields: meta?.fields ?? [
                            {
                                operation: singularResource,
                                fields: ['id'],
                                variables: {},
                            },
                        ],
                    })
                    const result = await client.request(query, gqlVariables)

                    return result[operation][singularResource]
                }),
            )
            return {
                data: response,
            }
        },

        //eslint-disable-next-line
        update: async ({ resource, id, variables, meta }) => {
            // we split for when resource is not detected correctly from url
            const singularResource = pluralize.singular(resource)
            const camelUpdateName = camelCase(`update-${singularResource}`)

            const inputFields = { id: id, ...variables }
            // we should give it meta?.operation if it exist but because it does not detect it properly we do it manually
            const operation = camelUpdateName

            // we should be using the singularResource instead of operaiton, but it does not detect it from the url as it should be
            const srCapitalizedFirstLetter = singularResource.charAt(0).toUpperCase() + singularResource.slice(1)

            const { query, variables: gqlVariables } = gql.mutation({
                operation, // operation in the mutation
                variables: {
                    inputFields: {
                        //many tariff is the only one who is not a
                        value: singularResource === 'manyTariff' ? variables : inputFields,
                        type: `Update${srCapitalizedFirstLetter}Fields!`,
                    },
                },
                // we should give it meta?.fields if it exist but because it does not detect it properly we do it manually
                // it does not detect it because it uses in the return fields mutation the same as query to get the resource
                // it's false because in the return fields of mutation we don't specify the edges{noode{resource ...}}
                // try to put meta?.fields ?? [ { ... }] and see the requests you will understand better.
                fields:
                    singularResource === 'manyTariff'
                        ? meta?.fields
                        : [
                              {
                                  operation: singularResource, // for the query in return
                                  fields: ['id'],
                                  variables: {},
                              },
                          ],
            })
            const response = await client.request(query, gqlVariables)

            return {
                data: response[operation][singularResource],
            }
        },

        //eslint-disable-next-line
        updateMany: async ({ resource, ids, variables, meta }) => {
            const singularResource = pluralize.singular(resource)
            const camelUpdateName = camelCase(`update-${singularResource}`)

            const operation = meta?.operation ?? camelUpdateName

            const response = await Promise.all(
                ids.map(async (id) => {
                    const { query, variables: gqlVariables } = gql.mutation({
                        operation,
                        variables: {
                            input: {
                                value: { where: { id }, data: variables },
                                type: `${camelUpdateName}Input`,
                            },
                        },
                        fields: meta?.fields ?? [
                            {
                                operation: singularResource,
                                fields: ['id'],
                                variables: {},
                            },
                        ],
                    })
                    const result = await client.request(query, gqlVariables)

                    return result[operation][singularResource]
                }),
            )
            return {
                data: response,
            }
        },

        //eslint-disable-next-line
        getOne: async ({ resource, id, meta }) => {
            const resourceName = resource
            const singularResource = pluralize.singular(resourceName)
            const camelResource = camelCase(singularResource)
            const resourceCapitalize = camelResource.charAt(0).toUpperCase() + camelResource.slice(1)

            const operation = meta?.operation ?? `all${resourceCapitalize}s`

            const { query, variables } = gql.query({
                operation,
                variables: {
                    where: { value: { id: id }, type: `Use${resourceCapitalize}Filter`, required: true },
                },
                //when click refreshButton from antd, meta is undifiend (for special character id's)
                fields: meta?.fields ?? [
                    {
                        edges: [
                            {
                                node: ['id'],
                            },
                        ],
                    },
                ],
            })

            const response = await client.request(query, variables)

            return {
                data: response[operation]['edges'][0]['node'],
            }
        },

        //eslint-disable-next-line
        deleteOne: async ({ resource, id, meta }) => {
            const singularResource = pluralize.singular(resource)
            const camelDeleteName = camelCase(`delete-${singularResource}`)

            const operation = meta?.operation ?? camelDeleteName

            const { query, variables } = gql.mutation({
                operation,
                variables: {
                    id_: {
                        value: id,
                        type: 'ID!',
                    },
                },
                fields: meta?.fields ?? ['status'],
            })

            const response = await client.request(query, variables)

            return {
                data: response[operation],
            }
        },

        //eslint-disable-next-line
        deleteMany: async ({ resource, ids, meta }) => {
            const singularResource = pluralize.singular(resource)
            const camelDeleteName = camelCase(`delete-${singularResource}`)

            const operation = meta?.operation ?? camelDeleteName

            const response = await Promise.all(
                ids.map(async (id) => {
                    const { query, variables: gqlVariables } = gql.mutation({
                        operation,
                        variables: {
                            id_: {
                                value: id,
                                type: 'ID!',
                            },
                        },
                        fields: meta?.fields ?? ['status'],
                    })
                    const result = await client.request(query, gqlVariables)

                    return result[operation]
                }),
            )
            return {
                data: response,
            }
        },

        //eslint-disable-next-line
        getApiUrl: () => {
            throw Error('Not implemented on refine-graphql data provider.')
        },

        //eslint-disable-next-line
        custom: async ({ url, method, headers, meta }) => {
            let gqlClient = client

            if (url) {
                gqlClient = new GraphQLClient(url, { headers })
            }

            if (meta) {
                if (meta.operation) {
                    if (method === 'get') {
                        const { query, variables } = gql.query({
                            operation: meta.operation,
                            fields: meta.fields,
                            variables: meta.variables,
                        })

                        const response = await gqlClient.request(query, variables)

                        return {
                            data: response[meta.operation],
                        }
                    } else {
                        const { query, variables } = gql.mutation({
                            operation: meta.operation,
                            fields: meta.fields,
                            variables: meta.variables,
                        })

                        const response = await gqlClient.request(query, variables)

                        return {
                            data: response[meta.operation],
                        }
                    }
                } else {
                    throw Error('GraphQL operation name required.')
                }
            } else {
                throw Error('GraphQL need to operation, fields and variables values in meta object.')
            }
        },
    }
}

export default customDataProvider
