import React from 'react'
import { DeleteButton, EditButton, List, useTable } from '@refinedev/antd'
import { Space, Table } from 'antd'
import { IContractType } from 'src/modules/SupplyContracts/ContractTypes/ContractTypes.d'

/**
 * This function returns a list of contract types.
 *
 * @returns List of contract types.
 */
export const ListContractTypes = () => {
    const { tableProps } = useTable<IContractType>({
        meta: {
            operation: 'allContractTypes',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
        sorters: {
            initial: [
                {
                    field: 'id',
                    order: 'asc',
                },
            ],
        },
    })

    return (
        <div>
            <List breadcrumb={null}>
                <Table
                    {...tableProps}
                    pagination={{
                        ...tableProps.pagination,
                        position: ['bottomRight'],
                        size: 'small',
                    }}
                    rowKey="id"
                >
                    <Table.Column dataIndex="name" title="Type de contrat" sorter />
                    <Table.Column<IContractType>
                        title="Actions"
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton hideText size="small" recordItemId={record.id} />
                                <DeleteButton hideText size="small" recordItemId={record.id} />
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </div>
    )
}
