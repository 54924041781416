import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import { InformationMenuConfigName } from 'src/configs'

import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'
import { CreateNews } from 'src/modules/Information/News/CreateNews'
import { ListNews } from 'src/modules/Information/News/ListNews'
import { EditNews } from 'src/modules/Information/News/EditNews'

/**
 * Resource EcoGests Name.
 */
export const resourceNewsName = 'news'

const newsUrls = getResourceUrlsFromResourceName(resourceNewsName, InformationMenuConfigName)

/**
 * News Resource config.
 */
export const NewsResourceConfig: IRefineResourceConfig = {
    name: resourceNewsName,
    meta: {
        parent: InformationMenuConfigName,
        label: 'News',
    },
    ...newsUrls,
}

/**
 * EcoGestsRoutes.
 */
export const newsRoutes: RouteProps[] = [
    {
        path: newsUrls.list,
        Component: ListNews,
    },
    {
        path: newsUrls.create,
        Component: CreateNews,
    },
    {
        path: newsUrls.edit,
        Component: EditNews,
    },
]
