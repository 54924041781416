import React, { useState } from 'react'
import { DateField, List, ShowButton, useTable } from '@refinedev/antd'
import { Button, Space, Table, Modal, UploadFile } from 'antd'
import { RcFile } from 'antd/es/upload'

import {
    IChameleonDelivery,
    ResponseUploadCsvType,
} from 'src/modules/Logistics/ChameleonDeliveries/ChameleonDeliveries.d'
import { InboxOutlined, DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import { Spin, UploadProps } from 'antd'
import { message, Upload } from 'antd'
import { useCustom, useNotification } from '@refinedev/core'
import { API_URL } from 'src/configs'

/**
 * This function returns a list of chameleon Deliveries.
 *
 * @returns List of Chameleon Deliveriees.
 */
export const ListChameleonDeliveries = () => {
    const { tableProps, tableQueryResult } = useTable<IChameleonDelivery>({
        dataProviderName: 'logisticDataProvider',
        meta: {
            operation: 'allChameleonDeliveries',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'reference',
                                'location',
                                'totalNrlinks',
                                'totalPallets',
                                'totalCartons',
                                'createdAt',
                                {
                                    orders: ['reference'],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    })

    // csv uploader Modal
    const [isUploadCsvModalVisible, setIsUploadCsvModalVisible] = useState(false)

    // csv file list state (will be only using a single one for now)
    const [fileList, setFileList] = useState<UploadFile[]>([])

    // state for csv to send to backend
    const [csvFile, setCsvFile] = useState('')

    /**
     * Function that verify if the file is a csv file.
     *
     * @param file Uploaded file.
     * @returns If uploaded file is in correct format type.
     */
    const checkFileType = (file: RcFile) => {
        const fileType = file.type
        if (fileType !== 'text/csv') {
            message.error('You can only upload CSV files!')
            return false
        }
        return true
    }

    /**
     * Function that verify if the file is good to upload, then convert it to string, the return default to prevent auto upload.
     *
     * @param file File that we want to upload.
     * @returns False to prevent from auto upload.
     */
    const handleBeforeUploadFile = (file: RcFile) => {
        // check if csv
        if (!checkFileType(file)) {
            // if notcsv empty the loaded file
            setFileList([])

            return false
        }
        // if it's a csv file add it to the list
        setFileList([file])

        // then convert file to string and save it
        const reader = new FileReader()

        //eslint-disable-next-line
        reader.onload = (event) => {
            let csvData = event.target?.result
            if (csvData instanceof ArrayBuffer) {
                csvData = new TextDecoder('utf-8').decode(csvData)
            }
            const csvString = csvData?.toString().replace(/\r?\n/g, '\n') ?? ''
            setCsvFile(csvString)
        }
        reader.readAsText(file)

        return false // to disable auto update
    }

    /**
     * Function that is called on the onClick of the delete file button.
     *
     * @param file File to remove from the list.
     */
    const handleRemove = (file: UploadFile) => {
        const index = fileList.indexOf(file)
        const newFileList = fileList.slice()
        newFileList.splice(index, 1)
        setFileList(newFileList)
    }

    const { Dragger } = Upload

    const { open } = useNotification()

    /**
     * Function that opens a notification based on params.
     *
     * @param message Message to Pop.
     * @param type Type of notification.
     * @param key Key.
     */
    const openNotification = (message: string, type: 'success' | 'error' | 'progress', key: string) => {
        // eslint-disable-next-line
        open!({
            message,
            type,
            key,
        })
    }

    const { refetch, isFetching: isFileUploading } = useCustom<ResponseUploadCsvType>({
        url: `${API_URL}/logistic`,
        dataProviderName: 'logisticDataProvider',
        config: {
            headers: {
                authorization: 'bearer ' + localStorage.getItem('token'),
            },
        },
        method: 'post',
        queryOptions: {
            enabled: false,
            retry: false,
            //eslint-disable-next-line
            onError(err) {
                openNotification(`${err.message.split(':')[0]}`, 'error', 'upload-csv-fail')
            },
            //eslint-disable-next-line
            onSuccess(data) {
                openNotification(
                    `Nombre d'nrlink qui n'ont pas été ajouté : ${data.data.notAddedNrlinks.length}`,
                    'success',
                    'upload-csv-success',
                )
                tableQueryResult.refetch()
            },
        },
        meta: {
            operation: 'uploadCsv',
            fields: [
                'status',
                {
                    notAddedNrlinks: ['erlGuid'],
                },
            ],
            variables: {
                inputFields: {
                    value: { csvString: csvFile },
                    type: 'UploadCsvFields!',
                },
            },
        },
        successNotification: {
            message: 'Livraison Chameleon Ajouté',
            type: 'success',
            key: 'upload-csv-success',
        },
        errorNotification: {
            message: "Erreur lors de l'ajout de la livraison Chameleon",
            type: 'error',
            key: 'upload-csv-error',
        },
    })

    const draggerProps: UploadProps = {
        name: 'file',
        multiple: false,
        accept: '.csv',
        maxCount: 1,
        fileList,
        disabled: isFileUploading, // can't add another file if a file is already uploading
        onRemove: handleRemove,
        beforeUpload: handleBeforeUploadFile,
    }

    return (
        <>
            <List
                headerProps={{
                    extra: (
                        <>
                            <Button
                                type="primary"
                                icon={<DownloadOutlined />}
                                onClick={() => setIsUploadCsvModalVisible(true)}
                            >
                                Charger une Nouvelle livraison
                            </Button>
                        </>
                    ),
                }}
                breadcrumb={null}
            >
                <Table
                    {...tableProps}
                    pagination={{
                        ...tableProps.pagination,
                        position: ['bottomRight'],
                        size: 'small',
                    }}
                    rowKey="id"
                >
                    <Table.Column dataIndex="reference" title="Reference de la livraison" />
                    <Table.Column dataIndex={['orders', 0, 'reference']} title="Reference de la commande" />
                    <Table.Column dataIndex="location" title="Lieu de livraison" />
                    <Table.Column dataIndex="totalPallets" title="Nombre de pallets" />
                    <Table.Column dataIndex="totalCartons" title="Nombre de cartons" />
                    <Table.Column dataIndex="totalNrlinks" title="Nombre de nrlinks" />
                    <Table.Column
                        dataIndex="createdAt"
                        title="Date de chargement de la facture"
                        render={(value) => <DateField value={value} format="DD-MM-YYYY" />}
                    />
                    <Table.Column<IChameleonDelivery>
                        title="Actions"
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <ShowButton hideText size="small" recordItemId={record.id} />
                            </Space>
                        )}
                    />
                </Table>
            </List>

            {/* Modal to upload csv */}

            <Modal
                open={isUploadCsvModalVisible}
                onOk={async () => {
                    csvFile && (await refetch())
                    setIsUploadCsvModalVisible(false)
                    setFileList([])
                    setCsvFile('')
                }}
                okText={isFileUploading ? 'Enregistrement de la facture' : 'Uploader la facture'}
                onCancel={() => {
                    setFileList([])
                    setCsvFile('')
                    setIsUploadCsvModalVisible(false)
                }}
                okButtonProps={{
                    icon: <UploadOutlined />,
                    disabled: !csvFile,
                    loading: isFileUploading,
                }}
                cancelButtonProps={{
                    disabled: isFileUploading,
                }}
                cancelText="Annuler"
            >
                <Dragger {...draggerProps} style={{ marginTop: '30px' }}>
                    {isFileUploading ? (
                        <div>
                            <Spin
                                tip="File is Being Loaded, it may take few minutes DON'T CLOSE !"
                                size="large"
                                style={{ marginTop: '30px', marginBottom: '30px' }}
                            />
                        </div>
                    ) : (
                        <>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                        </>
                    )}
                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                    <p className="ant-upload-hint">Support for a single upload and only CSV files.</p>
                    <p className="ant-upload-hint">BE AWARE THAT THE FILE SHOULD BE IN SPECEFIC FORMAT !</p>
                </Dragger>
            </Modal>
        </>
    )
}
