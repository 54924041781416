import React, { useState } from 'react'
import { DeleteButton, EditButton, List, useSelect, useTable } from '@refinedev/antd'
import { Input, Select, Space, Table } from 'antd'

import { ICommercialOffer } from 'src/modules/SupplyContracts/CommercialOffers/CommercialOffers.d'
import { IProvider } from '../Providers/Providers'
import { LabelInValueType, RawValueType } from 'src/modules/utils/commonTypes.d'

/**
 * This function returns a list of commercial offers.
 *
 * @returns List of commercial offers.
 */
export const ListCommercialOffers = () => {
    const [offerName, setOfferName] = useState<string | undefined>(undefined)
    const [providerId, setProviderId] = useState<string | undefined>(undefined)

    const { selectProps: providerSelectProps } = useSelect<IProvider>({
        resource: 'provider',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allProviders',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['name', 'id'],
                        },
                    ],
                },
            ],
        },
        onSearch:
            /**
             * Search input value for nrlink.
             *
             * @param inputValue IhdGuid Value of Nrlink.
             * @returns The nrlinks related to the search.
             */
            (inputValue) => [
                {
                    field: 'name',
                    operator: 'contains',
                    value: inputValue,
                },
            ],
    })

    const { tableProps, tableQueryResult } = useTable<ICommercialOffer>({
        meta: {
            operation: 'allCommercialOffers',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'name',
                                {
                                    provider: ['name'],
                                },
                                'isDeprecated',
                            ],
                        },
                    ],
                },
            ],
        },
        permanentFilter: [
            {
                field: 'name',
                operator: 'contains',
                value: offerName,
            },
            {
                field: 'providerId',
                operator: 'eq',
                value: providerId,
            },
        ],
        sorters: {
            initial: [
                {
                    field: 'id',
                    order: 'asc',
                },
            ],
        },
    })

    return (
        <div>
            <List breadcrumb={null}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginBottom: '15px',
                    }}
                >
                    <Input
                        value={offerName}
                        placeholder="Nom de l'offre"
                        style={{ width: '150px', marginRight: '60px' }}
                        onChange={(input) => {
                            input.target.value ? setOfferName(input.target.value) : setOfferName(undefined)
                            tableQueryResult.refetch()
                        }}
                    />
                    <Select
                        {...providerSelectProps}
                        placeholder="Nom du fournisseur"
                        style={{ marginRight: '60px', width: '250px' }}
                        allowClear
                        onSelect={(value: RawValueType | LabelInValueType) => {
                            typeof value === 'string' && setProviderId(value)
                        }}
                        onClear={() => setProviderId(undefined)}
                    />
                </div>
                <Table
                    {...tableProps}
                    pagination={{
                        ...tableProps.pagination,
                        position: ['bottomRight'],
                        size: 'small',
                    }}
                    rowKey="id"
                >
                    <Table.Column dataIndex="name" title="Nom Offre" sorter />
                    <Table.Column dataIndex={['provider', 'name']} title="Fournisseur" />
                    <Table.Column
                        dataIndex="isDeprecated"
                        title="Obsolète"
                        render={(isDeprecated: boolean) => (isDeprecated ? 'Oui' : 'Non')}
                    />
                    <Table.Column<ICommercialOffer>
                        title="Actions"
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton hideText size="small" recordItemId={record.id} />
                                <DeleteButton hideText size="small" recordItemId={record.id} />
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </div>
    )
}
