import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import { ListChameleonDeliveries, ShowChameleonDeliveries } from 'src/modules/Logistics/ChameleonDeliveries'
import { LogisticMenuConfigName } from 'src/configs'
import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'

/**
 * Resource ChameleonDeliveries Name.
 */
export const resourceChameleonDeliveriesName = 'chameleonDelivery'

const chameleonDeliveriesUrls = getResourceUrlsFromResourceName(resourceChameleonDeliveriesName, LogisticMenuConfigName)

/**
 * ChameleonDeliveries Resource config.
 */
export const ChameleonDeliveriesResourceConfig: IRefineResourceConfig = {
    name: resourceChameleonDeliveriesName,
    meta: {
        parent: LogisticMenuConfigName,
        label: 'livraison Chameleon',
    },
    list: chameleonDeliveriesUrls.list,
    show: chameleonDeliveriesUrls.show,
}

/**
 * ChameleonDeliveriesRoutes.
 */
export const chameleonDeliveriesRoutes: RouteProps[] = [
    {
        path: chameleonDeliveriesUrls.list,
        Component: ListChameleonDeliveries,
    },
    {
        path: chameleonDeliveriesUrls.show,
        Component: ShowChameleonDeliveries,
    },
]
