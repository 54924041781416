import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import {
    EditTariffTypes,
    ListTariffTypes,
    CreateTariffTypes,
} from 'src/modules/SupplyContracts/TariffTypes'
import { SupplyContractsMenuConfigName } from 'src/configs'

import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'

/**
 * Resource TariffTypes Name.
 */
export const resourceTariffTypesName = 'tariffType'

const tariffTypesUrls = getResourceUrlsFromResourceName(resourceTariffTypesName, SupplyContractsMenuConfigName)

/**
 * TariffTypes Resource config.
 */
export const TariffTypesResourceConfig: IRefineResourceConfig = {
    name: resourceTariffTypesName,
    meta: {
        parent: SupplyContractsMenuConfigName,
        label: 'Type de Tarifs',
    },
    ...tariffTypesUrls,
}

/**
 * TariffTypesRoutes.
 */
export const tariffTypesRoutes: RouteProps[] = [
    {
        path: tariffTypesUrls.list,
        Component: ListTariffTypes,
    },
    {
        path: tariffTypesUrls.create,
        Component: CreateTariffTypes,
    },
    {
        path: tariffTypesUrls.edit,
        Component: EditTariffTypes,
    }
]
