/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo } from 'react'
import { Edit, useForm, ListButton } from '@refinedev/antd'
import { Checkbox, Form, Input, Select } from 'antd'
import { useParams } from 'react-router-dom'
import { SelectEcoGestRooms } from 'src/modules/Advices/EcoGestRooms'
import { IEcoGest } from 'src/modules/Advices/EcoGests/EcoGests.d'
import { IEcoGestPole } from 'src/modules/Advices/EcoGestPoles/EcoGestPoles.d'
import { IEcoGestRoom } from 'src/modules/Advices/EcoGestRooms/EcoGestRooms.d'
import { SelectEcoGestPoles } from 'src/modules/Advices/EcoGestPoles'
import { isString } from 'lodash'
import {
    equipments as EQUIPMENTS,
    energies as ENERGIES,
    homeConfiguration as HOME_CONFIGURATION,
} from 'src/modules/Advices/EcoGests/utils'
import { SelectOptionsType } from 'src/modules/utils/commonTypes'

/**
 * This function returns a view to edit eco gests.
 *
 * @returns View to Edit eco gests.
 */
export const EditEcoGests = () => {
    const { id } = useParams()
    const { formProps, saveButtonProps, queryResult, onFinish } = useForm<IEcoGest>({
        action: 'edit',
        resource: 'ecoGest',
        id: id,
        queryOptions: {
            enabled: true,
            cacheTime: 0,
        },
        meta: {
            operation: 'allEcoGests', // the query operation
            fields: [
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'title',
                                'description',
                                'percentageSaved',
                                'highlighted',
                                'urlIcon',
                                'infos',
                                'equipments',
                                'energies',
                                'homeConfigurations',
                                {
                                    poleTags: [
                                        {
                                            edges: [
                                                {
                                                    node: ['id', 'type'],
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    roomTags: [
                                        {
                                            edges: [
                                                {
                                                    node: ['id', 'type'],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    })

    const poleIds = queryResult?.data?.data.poleTags
        ? queryResult?.data.data.poleTags.edges
              // eslint-disable-next-line jsdoc/require-jsdoc
              .map(({ node }: { node: IEcoGestPole }) => node.id)
        : undefined

    const roomIds = queryResult?.data?.data.roomTags
        ? queryResult?.data.data.roomTags.edges
              // eslint-disable-next-line jsdoc/require-jsdoc
              .map(({ node }: { node: IEcoGestRoom }) => node.id)
        : undefined

    const equipmentsJSON: SelectOptionsType[] = useMemo(
        () => (isString(queryResult?.data?.data?.equipments!) ? JSON.parse(queryResult?.data?.data.equipments!) : []),
        [queryResult?.data?.data.equipments],
    )

    const energiesJSON: SelectOptionsType[] = useMemo(
        () => (isString(queryResult?.data?.data?.energies!) ? JSON.parse(queryResult?.data?.data.energies!) : []),
        [queryResult?.data?.data.energies],
    )

    const homeConfigurationsJSON: SelectOptionsType[] = useMemo(
        () =>
            isString(queryResult?.data?.data?.homeConfigurations!)
                ? JSON.parse(queryResult?.data?.data.homeConfigurations!)
                : [],
        [queryResult?.data?.data.homeConfigurations],
    )

    const defaultEquipmentsOptions = useMemo(
        () => equipmentsJSON?.map((eq) => ({ value: eq.value, label: eq.label })),
        [equipmentsJSON],
    )
    const defaultEnergiesOptions = useMemo(
        () => energiesJSON?.map((energy) => ({ value: energy.value, label: energy.label })),
        [energiesJSON],
    )
    const defaultHomeConfigurations = useMemo(
        () =>
            homeConfigurationsJSON.map((homeConfig) => ({
                value: homeConfig.value,
                label: homeConfig.label,
            })),
        [homeConfigurationsJSON],
    )

    const filteredEquipments = EQUIPMENTS.filter((eq) => !defaultEquipmentsOptions.includes({ value: eq.value } as any))
    const filteredEnergies = ENERGIES.filter(
        (energy) => !defaultEnergiesOptions.includes({ value: energy.value } as any),
    )
    const filteredHomeConfigurations = HOME_CONFIGURATION.filter(
        (homeConfig) => !defaultHomeConfigurations.includes(homeConfig),
    )

    const [equipments, setEquipments] = React.useState<SelectOptionsType[]>()
    const [energies, setEnergies] = React.useState<SelectOptionsType[]>()
    const [homeConfiguration, setHomeConfiguration] = React.useState<SelectOptionsType[]>()

    return (
        <Edit
            saveButtonProps={{ ...saveButtonProps }}
            headerProps={{
                title: "Détail de l'Ecogeste",
                extra: <ListButton resource="ecoGest" />,
            }}
            isLoading={queryResult?.isLoading}
        >
            <Form
                {...formProps}
                onFinish={(v) => {
                    const values = {
                        ...v,
                        equipments: JSON.stringify(equipments),
                        energies: JSON.stringify(energies),
                        homeConfigurations: JSON.stringify(homeConfiguration),
                    }
                    onFinish(values)
                }}
            >
                <Form.Item label="Titre" name="title">
                    <Input />
                </Form.Item>
                <Form.Item label="Description" name="description">
                    <Input />
                </Form.Item>
                <Form.Item label="% d'Economie" name="percentageSaved">
                    <Input />
                </Form.Item>
                <Form.Item label="Url de l'icône" name="urlIcon">
                    <Input />
                </Form.Item>
                <Form.Item label="Informations" name="infos">
                    <Input />
                </Form.Item>
                <Form.Item label="A la une" name="highlighted" valuePropName="checked">
                    <Checkbox />
                </Form.Item>
                <Form.Item label="Postes de consommations" name="poleIds">
                    <SelectEcoGestPoles defaultValue={poleIds} />
                </Form.Item>
                <Form.Item label="Pièces" name="roomIds">
                    <SelectEcoGestRooms defaultValue={roomIds} />
                </Form.Item>
                {queryResult?.isFetched && (
                    <>
                        <Form.Item label="Equipements">
                            <Select
                                mode="multiple"
                                defaultValue={
                                    defaultEquipmentsOptions?.filter((option) => option) as SelectOptionsType[]
                                }
                                options={filteredEquipments}
                                onChange={(_, options) => setEquipments(options as SelectOptionsType[])}
                            />
                        </Form.Item>

                        <Form.Item label="Energies">
                            <Select
                                mode="multiple"
                                defaultValue={defaultEnergiesOptions.filter((option) => option) as SelectOptionsType[]}
                                options={filteredEnergies}
                                onChange={(_, options) => setEnergies(options as SelectOptionsType[])}
                            />
                        </Form.Item>

                        <Form.Item label="Logement">
                            <Select
                                mode="multiple"
                                defaultValue={
                                    defaultHomeConfigurations.filter((option) => option) as SelectOptionsType[]
                                }
                                options={filteredHomeConfigurations}
                                onChange={(_, options) => setHomeConfiguration(options as SelectOptionsType[])}
                            />
                        </Form.Item>
                    </>
                )}
            </Form>
        </Edit>
    )
}
