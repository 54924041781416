import { SupplyContractsConfig } from 'src/modules/SupplyContracts/SupplyContractsConfig'
import { AdvicesConfig } from 'src/modules/Advices/AdvicesConfig'
import { LogisticsConfig } from 'src/modules/Logistics/LogisticsConfig'
import { NrlinksManagementConfig } from 'src/modules/NrlinksManagement/NrlinksManagementConfig'
import { InformationConfig } from 'src/modules/Information/InformationConfig'

/**
 * Refine App Resources.
 */
export const resources = [
    ...SupplyContractsConfig,
    ...AdvicesConfig,
    ...NrlinksManagementConfig,
    ...LogisticsConfig,
    ...InformationConfig,
]
