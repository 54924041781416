import React from 'react'
import { Edit, useForm, ListButton } from '@refinedev/antd'
import { Form, Input } from 'antd'
import { IProvider } from 'src/modules/SupplyContracts/Providers/Providers.d'
import { useParams } from 'react-router-dom'

/**
 * This function returns a view to edit providers.
 *
 * @returns View to Edit providers.
 */
export const EditProviders = () => {
    const { id } = useParams()
    const { formProps, saveButtonProps } = useForm<IProvider>({
        action: 'edit',
        resource: 'provider',
        id: id,
        queryOptions: {
            enabled: true,
        },
        meta: {
            operation: 'allProviders', // the query operation
            fields: [
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
    })

    return (
        <Edit
            saveButtonProps={saveButtonProps}
            headerProps={{
                title: 'Modifier détails du fournisseur',
                extra: <ListButton resource="provider" />,
            }}
        >
            <Form
                {...formProps}
                layout="vertical"
                onFinish={(values) =>
                    formProps.onFinish?.({
                        ...values,
                    })
                }
            >
                <Form.Item
                    label="Nom du fournisseur"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Edit>
    )
}
