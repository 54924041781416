import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import { EditEcoGests, ListEcoGests, ShowEcoGests, CreateEcoGests } from 'src/modules/Advices/EcoGests'
import { AdvicesMenuConfigName } from 'src/configs'

import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'

/**
 * Resource EcoGests Name.
 */
export const resourceEcoGestsName = 'ecoGest'

const ecoGestsUrls = getResourceUrlsFromResourceName(resourceEcoGestsName, AdvicesMenuConfigName)

/**
 * EcoGests Resource config.
 */
export const EcoGestsResourceConfig: IRefineResourceConfig = {
    name: resourceEcoGestsName,
    meta: {
        parent: AdvicesMenuConfigName,
        label: 'EcoGest',
    },
    ...ecoGestsUrls,
}

/**
 * EcoGestsRoutes.
 */
export const ecoGestsRoutes: RouteProps[] = [
    {
        path: ecoGestsUrls.list,
        Component: ListEcoGests,
    },
    {
        path: ecoGestsUrls.create,
        Component: CreateEcoGests,
    },
    {
        path: ecoGestsUrls.edit,
        Component: EditEcoGests,
    },
    {
        path: ecoGestsUrls.show,
        Component: ShowEcoGests,
    },
]
