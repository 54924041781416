import React from 'react'
import { Create, useForm } from '@refinedev/antd'
import { Form, Input } from 'antd'
import { IStore } from 'src/modules/Logistics/Stores/Stores.d'

/**
 * This function returns a view to create a store.
 *
 * @returns View to create a store.
 */
export const CreateStores = () => {
    const { formProps, saveButtonProps } = useForm<IStore>({
        dataProviderName: 'logisticDataProvider',
    })

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Nom du store"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Localisation du store"
                    name="location"
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Url de la plateforme associer" name="url">
                    <Input />
                </Form.Item>
                <Form.Item label="Url de l'admin associé au store" name="adminUrl">
                    <Input />
                </Form.Item>
            </Form>
        </Create>
    )
}
