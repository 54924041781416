/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Edit, useForm } from '@refinedev/antd'
import { DatePicker, Form, Input } from 'antd'
import { INews } from 'src/modules/Information/News/News.types'
import { useParams } from 'react-router-dom'

/**
 * Create news.
 *
 * @returns View to create one News.
 */
export const EditNews = () => {
    const { id } = useParams()
    const {
        formProps,
        saveButtonProps,
        onFinish: formOnSubmit,
    } = useForm<INews>({
        action: 'edit',
        resource: 'news',
        id: id,
        queryOptions: {
            enabled: true,
            cacheTime: 0,
        },
        meta: {
            operation: 'allNews',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'title', 'content', 'date', 'link'],
                        },
                    ],
                },
            ],
        },
    })
    return (
        <Edit saveButtonProps={saveButtonProps}>
            <Form
                {...formProps}
                onFinish={(v: INews | any) => {
                    const data = {
                        ...v,
                        date: new Date(v.date),
                    }
                    formOnSubmit(data)
                }}
            >
                <Form.Item
                    label="Titre"
                    name="title"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Contenu"
                    name="content"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Date"
                    name="date"
                    valuePropName="dateString"
                    normalize={(value) => value.toISOString()}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    label="Lien"
                    name="link"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Edit>
    )
}
