import baseAxios, { AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import {
    nrlinkStatisticsType,
    nrlinkWidgetFiltersType,
} from 'src/modules/NrlinksManagement/NrlinkWidgets/NrlinkWidgets'
import { useNotification } from '@refinedev/core'
import applyCaseMiddleware from 'axios-case-converter'

/**
 * Nrlink Widgets Api.
 */
export const NRLINK_WIDGETS_API = `${window._env_.REACT_APP_API_URL}/nrlink-statistics`

/**
 * TODO Document.
 */
const axios = applyCaseMiddleware(baseAxios, {
    ignoreHeaders: true,
    caseFunctions: {
        /**
         * TODO Document.
         *
         * @param input TODO Document.
         * @param options TODO Document.
         * @returns TODO Document.
         */
        //TODO Correct this
        // eslint-disable-next-line
        //@ts-ignore
        snake: (input: string, options: never) => {
            return input
                .split(/(?=[A-Z])/)
                .join('_')
                .toLowerCase()
        },
    },
})

/**
 * Nrlink Widgets Hook.
 *
 * @returns Nrlink Widgets Hook.
 */
export const useNrlinkWidgets = () => {
    const [isLoadingInProgress, setIsLoadingInProgress] = useState(true)
    const [nrlinkStatistics, setNrlinkStatistics] = useState<nrlinkStatisticsType | null>(null)
    const { open: openToast } = useNotification()

    /**
     * Load NrlinkStatistics Handler.
     */
    const loadNrlinkStatistics = useCallback(
        async (body?: nrlinkWidgetFiltersType) => {
            setIsLoadingInProgress(true)
            try {
                const { data } = await axios.post<
                    nrlinkStatisticsType,
                    AxiosResponse<nrlinkStatisticsType>,
                    nrlinkWidgetFiltersType
                >(NRLINK_WIDGETS_API, body, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                })

                setNrlinkStatistics(data)
            } catch (error) {
                // eslint-disable-next-line
                openToast!({
                    type: 'error',
                    description: 'Erreur...',
                    message: 'Chargement de Widgets',
                })
            }
            setIsLoadingInProgress(false)
        },
        [openToast],
    )

    useEffect(() => {
        loadNrlinkStatistics()
    }, [loadNrlinkStatistics])

    return {
        loadNrlinkStatistics,
        isLoadingInProgress,
        nrlinkStatistics,
    }
}
