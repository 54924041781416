import React from 'react'
import { Create, useForm } from '@refinedev/antd'
import { DatePicker, Form, Input } from 'antd'
import { INews } from 'src/modules/Information/News/News.types'

const { TextArea } = Input

/**
 * Create news.
 *
 * @returns View to create one News.
 */
export const CreateNews = () => {
    const { formProps, saveButtonProps } = useForm<INews>()
    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps}>
                <Form.Item
                    label="Titre"
                    name="title"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Contenu"
                    name="content"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <TextArea rows={3} />
                </Form.Item>
                <Form.Item
                    label="Date"
                    name="date"
                    valuePropName="dateString" // so it can take the value as dateString
                    normalize={(value) => value.toISOString()}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    label="Lien"
                    name="link"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Create>
    )
}
