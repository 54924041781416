import React from 'react'
import { DeleteButton, EditButton, List, useTable } from '@refinedev/antd'
import { Space, Table } from 'antd'
import { INews } from 'src/modules/Information/News/News.types'
import dayjs from 'dayjs'

/**
 * Create news.
 *
 * @returns View to create one News.
 */
export const ListNews = () => {
    const { tableProps } = useTable<INews>({
        meta: {
            operation: 'allNews',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'title', 'content', 'date', 'link'],
                        },
                    ],
                },
            ],
        },
        sorters: {
            initial: [
                {
                    field: 'id',
                    order: 'asc',
                },
            ],
        },
    })
    return (
        <List breadcrumb={null}>
            <Table
                {...tableProps}
                pagination={{
                    ...tableProps.pagination,
                    position: ['bottomRight'],
                    size: 'small',
                }}
                rowKey="id"
            >
                <Table.Column dataIndex="title" title="Titre" ellipsis />
                <Table.Column dataIndex="content" title="Contenu" ellipsis />
                <Table.Column
                    dataIndex="date"
                    title="Date"
                    ellipsis
                    render={(value) => dayjs(value).format('DD/MM/YYYY HH:mm:ss')}
                />
                <Table.Column dataIndex="link" title="Lien" ellipsis />
                <Table.Column<INews>
                    title="Actions"
                    dataIndex="actions"
                    render={(_, record) => (
                        <Space>
                            <EditButton hideText size="small" recordItemId={record.id} />
                            {/* <ShowButton hideText size="small" recordItemId={record.id} /> */}
                            <DeleteButton hideText size="small" recordItemId={record.id} />
                        </Space>
                    )}
                />
            </Table>
        </List>
    )
}
