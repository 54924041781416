import React, { useState } from 'react'
import { Create, useForm, useSelect } from '@refinedev/antd'
import { Form, Input, Select, Cascader, Checkbox, Radio, TimePicker } from 'antd'

import { ITariffType } from 'src/modules/SupplyContracts/TariffTypes/TariffTypes.d'
import { ITariffComponent } from 'src/modules/SupplyContracts/TariffComponents/TariffComponents.d'
import { IContract } from 'src/modules/SupplyContracts/Contracts/Contracts.d'
import { daysOptions } from 'src/modules/SupplyContracts/TariffComponents/common'
import { formatTimePickerValue } from 'src/modules/utils'

/**
 * This function returns a view to create a Tariff Component.
 *
 * @returns View to create a Tariff Component.
 */
export const CreateTariffComponents = () => {
    const { formProps, saveButtonProps } = useForm<ITariffComponent>()

    const [isContractRelated, setIsContractRelated] = useState(false)

    const { selectProps: selectedPropsContract } = useSelect<IContract>({
        resource: 'contract',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allContracts',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
        sorters: [
            {
                field: "name",
                order: "asc"
            }
        ]
    })

    const { selectProps: selectedPropsTariffType } = useSelect<ITariffType>({
        resource: 'tariffType',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allTariffTypes',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
        sorters: [
            {
                field: "name",
                order: "asc"
            }
        ]
    })

    const RelatedToOptions = [
        {
            value: true,
            label: 'contrat',
        },
        {
            value: false,
            label: 'Type de tarif',
        },
    ]

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="horizontal">
                <Form.Item
                    label="Nom de la composante"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Cascader
                        options={RelatedToOptions}
                        placeholder="Relié à"
                        onChange={(value) =>
                            value && value[0] ? setIsContractRelated(true) : setIsContractRelated(false)
                        }
                        style={{
                            marginBottom: '20px',
                        }}
                    />
                </div>

                {isContractRelated ? (
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label="Contrat"
                        name="contractId"
                    >
                        <Select disabled={!isContractRelated} {...selectedPropsContract} />
                    </Form.Item>
                ) : (
                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label="Type de Tarif"
                        name="tariffTypeId"
                    >
                        <Select disabled={isContractRelated} {...selectedPropsTariffType} />
                    </Form.Item>
                )}

                <Form.Item
                    label="Utiliser le compteur heures creuses"
                    name="useMeterOffpeak"
                    initialValue={false}
                    valuePropName="checked"
                >
                    <Checkbox>Oui</Checkbox>
                </Form.Item>

                <Form.Item initialValue={[]} label="Appliquer aux jours" name="byweekday">
                    <Checkbox.Group options={daysOptions} />
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label="Fréquence"
                    name="freq"
                >
                    <Radio.Group>
                        <Radio value="DAILY"> Quotidienne </Radio>
                        <Radio value="MONTHLY"> Mensuelle </Radio>
                        <Radio value="YEARLY"> Annuelle </Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item label="Type de tariff de la composante ?" name="componentTariffType">
                    <Radio.Group>
                        <Radio value="base"> Base </Radio>
                        <Radio value="hc"> Heure pleine </Radio>
                        <Radio value="hp"> Heure creuse </Radio>
                        <Radio value="jour_bleu"> Jour bleu </Radio>
                        <Radio value="jour_rouge"> Jour rouge </Radio>
                        <Radio value="jour_blanc"> Jour blanc </Radio>
                        <Radio value={null}>Rien</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Heure Début"
                    name="startTime"
                    valuePropName="dateString"
                    normalize={formatTimePickerValue}
                >
                    <TimePicker showNow={false} format="HH:mm" />
                </Form.Item>

                <Form.Item
                    label="Heure Fin"
                    name="endTime"
                    valuePropName="dateString"
                    normalize={formatTimePickerValue}
                >
                    <TimePicker showNow={false} format="HH:mm" />
                </Form.Item>
            </Form>
        </Create>
    )
}
