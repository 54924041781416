import {
    EditCommercialOffers,
    ListCommercialOffers,
    CreateCommercialOffers,
} from 'src/modules/SupplyContracts/CommercialOffers'

import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import { SupplyContractsMenuConfigName } from 'src/configs'
import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'

/**
 * Resource CommercialOffers Name.
 */
export const resourceCommercialOffersName = 'commercialOffer'

const commercialOfferUrls = getResourceUrlsFromResourceName(resourceCommercialOffersName, SupplyContractsMenuConfigName)
/**
 * CommercialOffers Resource config.
 */
export const commercialOffersResourceConfig: IRefineResourceConfig = {
    name: resourceCommercialOffersName,
    meta: {
        parent: SupplyContractsMenuConfigName,
        label: 'Offres Commerciales',
    },

    ...commercialOfferUrls,
}

/**
 * CommercialOfferRoutes.
 */
export const commercialOffersRoutes: RouteProps[] = [
    {
        path: commercialOfferUrls.list,
        Component: ListCommercialOffers,
    },
    {
        path: commercialOfferUrls.create,
        Component: CreateCommercialOffers,
    },
    {
        path: commercialOfferUrls.edit,
        Component: EditCommercialOffers,
    }
]
