import dayjs from 'dayjs'

/**
 * Utility function to return Resource Urls from Resource Name.
 *
 * @param resourceName Resource Name.
 * @param parentName Parent Base Name.
 * @returns Resource Urls.
 */
export const getResourceUrlsFromResourceName = (resourceName: string, parentName?: string) => {
    const basePath = parentName ? parentName + '/' + resourceName : resourceName
    return {
        list: '/' + basePath,
        edit: '/' + basePath + '/edit/:id',
        show: '/' + basePath + '/show/:id',
        create: '/' + basePath + '/create',
    }
}

/**
 * Convert Time string to DateTime using a default date of today.
 *
 * @param time Time as string that we want to convert.
 * @returns Date.
 */
export const convertTimeToDate = (time: string | undefined): dayjs.Dayjs | undefined => {
    if (!time) {
        return undefined
    }

    const defaultDate = dayjs()
    const splitedTime = time.split(':')

    // Parse the time and replace values in the default date
    return defaultDate.hour(Number(splitedTime[0])).minute(Number(splitedTime[1])).second(Number(splitedTime[2]))
}

/**
 * Function that converts the result of the timepicker to HH:mm:ss format to send to backend.
 *
 * @param value Value selected in the timePicker.
 * @returns Formated value as a string or null.
 */
export const formatTimePickerValue = (value: dayjs.Dayjs | null): string | null => {
    return value ? value.format('HH:mm:ss') : null
}
