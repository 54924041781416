import React, { useState } from 'react'
import {
    Edit,
    useForm,
    ListButton,
    useSelect,
    List,
    useModalForm,
    DeleteButton,
    EditButton,
    CreateButton,
    useTable,
} from '@refinedev/antd'

import { Form, Input, Select, Modal, DatePicker, Space, Drawer, Checkbox, Radio, TimePicker } from 'antd'

import { ITariffType } from 'src/modules/SupplyContracts/TariffTypes/TariffTypes.d'
import { ITariffComponent } from 'src/modules/SupplyContracts/TariffComponents/TariffComponents.d'
import {
    IContract,
    IContractPeriod,
    ITariff,
    IManyTariffsDataTableRow,
    RecordValuesInForm,
    TariffIdWithPrice,
} from 'src/modules/SupplyContracts/Contracts/Contracts.d'

import { ICommercialOffer } from 'src/modules/SupplyContracts/CommercialOffers/CommercialOffers.d'
import { IContractType } from 'src/modules/SupplyContracts/ContractTypes/ContractTypes.d'

import { Tabs, Table, Popconfirm } from 'antd'
import moment from 'moment'
import _ from 'lodash'
import { DeleteOutlined } from '@ant-design/icons'
import { useDeleteMany, useNavigation } from '@refinedev/core'

import { useParams } from 'react-router-dom'
import { daysOptions } from 'src/modules/SupplyContracts/TariffComponents/common'
import dayjs from 'dayjs'
import { formatTimePickerValue } from 'src/modules/utils'

const { TabPane } = Tabs

/**
 * This function returns a view to edit tariff component.
 *
 * @returns View to Edit tariff component.
 */
export const EditContracts = () => {
    const { id } = useParams()
    const { mutate } = useDeleteMany()
    const { push } = useNavigation()

    const { formProps, saveButtonProps, queryResult } = useForm<IContract>({
        action: 'edit',
        resource: 'contract',
        id: id,
        meta: {
            operation: 'allContracts', // the query operation to fill the form
            fields: [
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'name',
                                {
                                    commercialOffer: ['id', 'name'],
                                },
                                {
                                    contractType: ['id', 'name'],
                                },
                                {
                                    tariffType: ['id', 'name'],
                                },
                                {
                                    tariffComponents: ['id', 'name'],
                                },
                                {
                                    contractPeriods: [
                                        'id',
                                        'startAt',
                                        'endAt',
                                        {
                                            tariffs: [
                                                'id',
                                                'power',
                                                'price',
                                                {
                                                    tariffComponent: ['id', 'name'],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    })

    const { selectProps: selectedPropsCommercialOffer } = useSelect<ICommercialOffer>({
        resource: 'commercialOffer',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allCommercialOffers',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
        sorters: [
            {
                field: 'name',
                order: 'asc',
            },
        ],
    })

    const { selectProps: selectedPropsContractType } = useSelect<IContractType>({
        resource: 'contractType',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allContractTypes',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
        sorters: [
            {
                field: 'name',
                order: 'asc',
            },
        ],
    })

    const { selectProps: selectedPropsTariffType } = useSelect<ITariffType>({
        resource: 'tariffType',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allTariffTypes',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
        sorters: [
            {
                field: 'name',
                order: 'asc',
            },
        ],
    })

    // record of the data
    const record = queryResult?.data?.data

    // values of the form
    const defaultValueCommercialOffer = record?.commercialOffer?.id
    const defaultValueTariffType = record?.tariffType?.id
    const defaultValueContractType = record?.contractType.id

    // this components are pretty much the same used in showContracts

    /**
     * This Variable defines witch columns we will have in the tables.
     * A Column is the name of a tariff Component and his id and the price of the tariff.
     * So we map through the tariff components related to the contract to know what to have.
     *
     * We will use this variable like a default value for all the tariffs.
     */
    const tariffsColumnsFormat: /**
     * We have key/value object witch correspond to tariffComponentName: {tariffPrice,TariffComponentId,TariffId}.
     */
    {
        [key: string]: TariffIdWithPrice
    } = {}

    // to make it more easy to get the id of tariff component from it's name
    //eslint-disable-next-line
    const tariffComponentsWithTheirIds: { [name: string]: string } = {}

    // we initialise the columns
    record?.tariffComponents.forEach((tc: ITariffComponent) => {
        tariffsColumnsFormat[tc.name] = { price: 0, tariffId: '', tariffComponentId: tc.id }
        tariffComponentsWithTheirIds[tc.name] = tc.id
    })

    /**
     * A function that format the tariffs for the table to read.
     *
     * @param tariffs The Tariffs we want to format.
     * @returns Array of tariffs.
     */
    const formatDataTable = (
        /** The Tariffs that we want to convert to be able to read it in the table columns. */
        tariffs: ITariff[] | undefined,
    ) => {
        return _(tariffs)
            .groupBy('power')
            .map((values, key) => ({ power: key, ...constructeTariffs(values) }))
            .value()
    }

    /**
     * This function is to fill the tariff components of the tariffs we have.
     *
     * @param values Values of the tariffs we have.
     * @returns Values we have formated so that the table can read it.
     */
    const constructeTariffs = (
        /**
         * Values we want to format.
         */
        values: ITariff[],
    ) => {
        //eslint-disable-next-line
        const newValues = {} as { [key: string]: TariffIdWithPrice }

        _.map(values, (value) => {
            newValues[value.tariffComponent.name] = {
                price: value.price,
                tariffId: value.id,
                tariffComponentId: value.tariffComponent.id,
            }
        })

        return newValues
    }

    // This part is for the add and remove contract period

    const {
        modalProps: modalPropsContractPeriod,
        formProps: formPropsContractPeriod,
        show: showModalContractPeriod,
    } = useModalForm<IContractPeriod>({
        action: 'create',
        resource: 'contractPeriod',
        autoSubmitClose: true,
        redirect: false,
        meta: {
            fields: [{ contractPeriod: ['id', 'startAt', 'endAt'] }],
        },
        //eslint-disable-next-line
        onMutationSuccess(data, variables, context) {
            queryResult?.refetch()
        },
    })

    // This part is for the tariffs ( delete / edit / add )

    //eslint-disable-next-line
    const deleteManyTariffs = (record: IManyTariffsDataTableRow) => {
        const tariffIds: string[] = []

        const { power: number, ...tariffComponents } = record
        Object.keys(tariffComponents).map((tariffComponentName: string) =>
            tariffIds.push(tariffComponents[tariffComponentName].tariffId),
        )

        mutate(
            {
                resource: 'tariffs',
                ids: tariffIds,
            },
            {
                //eslint-disable-next-line
                onSuccess: () => queryResult?.refetch(),
            },
        )
    }

    const {
        modalProps: modalPropsCreateManyTariffs,
        formProps: formPropsCreateManyTariffs,
        show: showModalCreateManyTariffs,
        id: idCreatePeriod,
    } = useModalForm<IManyTariffsDataTableRow>({
        action: 'create',
        resource: 'manyTariffs',
        autoSubmitClose: true,
        redirect: false,
        autoResetForm: true,
        meta: {
            fields: [{ createdTariffs: ['id', 'power', 'price'] }],
        },
        //eslint-disable-next-line
        onMutationSuccess(data, variables, context) {
            queryResult?.refetch()
        },
    })

    const {
        modalProps: modalPropsCreateManyTariffsFromMultiplePowerFields,
        formProps: formPropsCreateManyTariffsFromMultiplePowerFields,
        show: showModalCreateManyTariffsFromMultiplePower,
        id: idCreatePeriodFromMultiplePowerFields,
    } = useModalForm<IManyTariffsDataTableRow>({
        action: 'create',
        resource: 'manyTariffsFromMultiplePower',
        autoSubmitClose: true,
        redirect: false,
        autoResetForm: true,
        meta: {
            fields: [{ createdTariffs: ['id', 'power', 'price'] }],
        },
        //eslint-disable-next-line
        onMutationSuccess(data, variables, context) {
            queryResult?.refetch()
        },
    })

    const {
        modalProps: modalPropsUpdateManyTariffs,
        formProps: formPropsUpdateManyTariffs,
        show: showModalUpdateManyTariffs,
        id: idUpdatePeriod,
    } = useModalForm<IManyTariffsDataTableRow>({
        action: 'edit',
        resource: 'manyTariffs',
        autoSubmitClose: true,
        redirect: false,
        queryOptions: {
            enabled: false,
        },
        meta: {
            fields: [{ updatedTariffs: ['id', 'power', 'price'] }],
        },
        //eslint-disable-next-line
        onMutationSuccess(data, variables, context) {
            queryResult?.refetch()
        },
    })

    // This is used to get the tariffs on the modal because we can't passe them directly when we show update modal.
    const [defaultValuesUpdateManyTariff, setDefaultValuesUpdateManyTariff] = useState<
        IManyTariffsDataTableRow | undefined
    >(undefined)

    /**
     * This function is called when we click on edit tariffs.
     *
     * @param record The record that contains the power and tariffs corresponding to tariff components.
     * @param periodId The contract period of the record we want to update.
     */
    const editRecordTariffs = (
        /** */
        record: IManyTariffsDataTableRow,
        /**
         *
         */
        periodId: string,
    ) => {
        setDefaultValuesUpdateManyTariff(record)
        showModalUpdateManyTariffs(periodId)
    }

    const [activeKey, setActiveKey] = React.useState(record?.contractPeriods[0]?.id)

    // This part is to add a new tariff component and list the tariff components that are linked to this contract

    const [activeDrawer, setActiveDrawer] = useState(false)

    const { tableProps, tableQueryResult } = useTable<ITariffComponent>({
        resource: 'tariffComponent',
        meta: {
            operation: 'allTariffComponents',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name', 'startTime', 'endTime'],
                        },
                    ],
                },
            ],
        },
        filters: {
            permanent: [
                {
                    field: 'contractId',
                    operator: 'eq',
                    value: id,
                },
            ],
        },
        sorters: {
            initial: [
                {
                    field: 'id',
                    order: 'asc',
                },
            ],
        },
        pagination: {
            pageSize: 50,
        },
    })

    const {
        modalProps: modalPropsCreateTariffComponents,
        formProps: formPropsCreateTariffComponents,
        show: showModalCreateTariffComponents,
    } = useModalForm<ITariffComponent>({
        action: 'create',
        resource: 'tariffComponent',
        autoSubmitClose: true,
        redirect: false,
        autoResetForm: true,
        meta: {
            fields: [{ tariffComponent: ['id'] }],
        },
        //eslint-disable-next-line
        onMutationSuccess(data, variables, context) {
            queryResult?.refetch()
            tableQueryResult?.refetch()
            setActiveDrawer(true)
        },
    })

    const {
        modalProps: modalPropsUpdateContractPeriod,
        formProps: formPropsUpdateContractPeriod,
        show: showModalUpdateContractPeriod,
    } = useModalForm<IContractPeriod>({
        action: 'edit',
        resource: 'contractPeriod',
        autoSubmitClose: true,
        redirect: false,
        meta: {
            fields: [{ contractPeriod: ['id', 'startAt', 'endAt'] }],
        },
        queryOptions: {
            enabled: false,
        },
        //eslint-disable-next-line
        onMutationSuccess(data, variables, context) {
            queryResult?.refetch()
        },
    })

    // This is used to get the contract period initial values.
    const [initialValuesContractPeriod, setInitialValuesContractPeriod] = useState<IContractPeriod | undefined>(
        undefined,
    )

    /**
     * This function is called when we click on edit Period.
     *
     * @param period The contract period id of the period we want to update.
     */
    const editContractPeriod = (
        /**
         *
         */
        period: IContractPeriod,
    ) => {
        setInitialValuesContractPeriod(period)
        showModalUpdateContractPeriod(period.id)
    }

    return (
        <>
            <Edit
                saveButtonProps={saveButtonProps}
                headerProps={{
                    title: 'Modifer détails du Contrat',
                    extra: (
                        <>
                            <ListButton resource="contract" />
                            <ListButton
                                type="primary"
                                resource="tariffComponents"
                                onClick={() => setActiveDrawer(true)}
                            >
                                Lister les Composantes
                            </ListButton>
                        </>
                    ),
                }}
                isLoading={queryResult?.isLoading}
            >
                <Form
                    {...formProps}
                    layout="horizontal"
                    onFinish={(values) =>
                        formProps.onFinish?.({
                            ...values,
                        })
                    }
                >
                    <Form.Item
                        label="Nom du contrat"
                        name="name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label="Offre associée"
                        name="commercialOfferId"
                        initialValue={defaultValueCommercialOffer}
                    >
                        <Select {...selectedPropsCommercialOffer} />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label="Type de contrat associé"
                        name="contractTypeId"
                        initialValue={defaultValueContractType}
                    >
                        <Select {...selectedPropsContractType} />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label="Type de tarif associé"
                        name="tariffTypeId"
                        initialValue={defaultValueTariffType}
                    >
                        <Select {...selectedPropsTariffType} />
                    </Form.Item>
                </Form>
            </Edit>

            {/* List periods in tabs */}

            <List
                title="Périodes associées au contrat"
                breadcrumb={null}
                headerProps={{
                    extra: <CreateButton onClick={() => showModalContractPeriod()}>Ajouter Période</CreateButton>,
                }}
            >
                <Tabs
                    hideAdd
                    type="editable-card"
                    animated={true}
                    activeKey={activeKey}
                    onChange={(key) => setActiveKey(key)}
                    tabPosition="right"
                >
                    {record?.contractPeriods.map((period) => (
                        <TabPane
                            tab={`${moment(period.startAt).format('DD MMMM YYYY')} => ${moment(period.endAt).format(
                                'DD MMMM YYYY',
                            )}`}
                            key={period.id}
                            closeIcon={
                                <>
                                    <EditButton onClick={() => editContractPeriod(period)} />
                                    <DeleteButton
                                        onSuccess={(e) => {
                                            queryResult?.refetch()
                                            setActiveKey(record?.contractPeriods[0]?.id)
                                        }}
                                        resource="contractPeriod"
                                        hideText
                                        size="small"
                                        recordItemId={period.id}
                                    />
                                </>
                            }
                        >
                            <CreateButton
                                resource="manyTariffs"
                                onClick={() => showModalCreateManyTariffs(period.id)}
                                style={{ marginRight: '1rem' }}
                            >
                                Ajouter une Puissance
                            </CreateButton>

                            <CreateButton
                                resource=""
                                onClick={() => showModalCreateManyTariffsFromMultiplePower(period.id)}
                            >
                                Ajouter tariffs avec puissance incrémenté
                            </CreateButton>

                            <Table dataSource={formatDataTable(period.tariffs)} pagination={false} scroll={{ x: true }}>
                                <Table.Column dataIndex="power" title="Puissance (kva)" key={0} />

                                {Object.keys(tariffsColumnsFormat)?.map((tariffComponent, index) => (
                                    <Table.Column
                                        key={index + 1} // the key = 0 is for power
                                        dataIndex={[`${tariffComponent}`, 'price']}
                                        title={`${tariffComponent} (€)`}
                                    />
                                ))}

                                <Table.Column
                                    title="Actions"
                                    dataIndex="actions"
                                    key="actions"
                                    render={(_, record: IManyTariffsDataTableRow) => (
                                        <Space>
                                            <EditButton
                                                hideText
                                                size="small"
                                                onClick={(e) => editRecordTariffs(record, period.id)}
                                            />
                                            <Popconfirm
                                                title="êtes vous sur ?"
                                                onConfirm={() => deleteManyTariffs(record)}
                                            >
                                                <DeleteOutlined
                                                    style={{
                                                        color: 'red',
                                                    }}
                                                />
                                            </Popconfirm>
                                        </Space>
                                    )}
                                />
                            </Table>
                        </TabPane>
                    ))}
                </Tabs>
            </List>

            {/* Modal for creation of period */}

            <Modal {...modalPropsContractPeriod}>
                <Form {...formPropsContractPeriod} layout="horizontal">
                    <Form.Item label="contrat" name="contractId" initialValue={record?.id}>
                        <Select
                            disabled
                            options={[
                                {
                                    value: record?.id,
                                    label: record?.name,
                                },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Commence le"
                        name="startAt"
                        valuePropName="dateString" // so it can take the value as dateString
                        normalize={(value) => value.toISOString()}
                    >
                        <DatePicker />
                    </Form.Item>

                    <Form.Item
                        label="Finit le "
                        name="endAt"
                        valuePropName="dateString" // so it can take the value as dateString
                        normalize={(value) => value.toISOString()}
                    >
                        <DatePicker />
                    </Form.Item>
                </Form>
            </Modal>

            {/* Modal for updating contract period dates */}

            <Modal {...modalPropsUpdateContractPeriod}>
                <Form {...formPropsUpdateContractPeriod} layout="horizontal">
                    <Form.Item label="contrat" name="contractId" initialValue={id}>
                        <Select
                            disabled
                            defaultValue={id}
                            options={[
                                {
                                    value: record?.id,
                                    label: record?.name,
                                },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Commence le"
                        name="startAt"
                        valuePropName="dateString" // so it can take the value as dateString
                        normalize={(value) => value?.toISOString()}
                    >
                        <DatePicker defaultValue={dayjs(initialValuesContractPeriod?.startAt)} />
                    </Form.Item>

                    <Form.Item
                        label="Finit le "
                        name="endAt"
                        valuePropName="dateString" // so it can take the value as dateString
                        normalize={(value) => value?.toISOString()}
                    >
                        <DatePicker defaultValue={dayjs(initialValuesContractPeriod?.endAt)} />
                    </Form.Item>
                </Form>
            </Modal>

            {/* Modal for creation of many tariffs */}
            <Modal {...modalPropsCreateManyTariffs}>
                <Form
                    {...formPropsCreateManyTariffs}
                    layout="horizontal"
                    onFinish={(values: RecordValuesInForm | unknown) => {
                        // before sending values we format them.
                        const returnedValuesForm = values as RecordValuesInForm

                        const { power, contractPeriodId, ...rest } = returnedValuesForm
                        /**
                         * We send tariffs witch is an array with a tariffcomponentId and it's price.
                         */
                        //eslint-disable-next-line
                        const tariffs: { tariffComponentId: string; price: number }[] = []

                        Object.keys(rest).map((tariffComponentKey) =>
                            tariffs.push({
                                tariffComponentId: tariffComponentsWithTheirIds[tariffComponentKey],
                                price: rest[tariffComponentKey],
                            }),
                        )

                        const finalValues = {
                            power: power,
                            contractPeriodId: contractPeriodId,
                            tariffs: tariffs,
                        }
                        formPropsCreateManyTariffs.onFinish?.({
                            ...finalValues,
                        })
                    }}
                >
                    <Form.Item label="Identifiant period" name="contractPeriodId" initialValue={idCreatePeriod}>
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                        label="Puissance (kva)"
                        name="power"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input type={'number'} />
                    </Form.Item>

                    {Object.keys(tariffsColumnsFormat)?.map((tariffComponentName) => (
                        <Form.Item
                            key={tariffsColumnsFormat[tariffComponentName].tariffComponentId}
                            label={`${tariffComponentName} (€)`}
                            name={`${tariffComponentName}`}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input type={'number'} />
                        </Form.Item>
                    ))}
                </Form>
            </Modal>
            <Modal {...modalPropsCreateManyTariffsFromMultiplePowerFields}>
                <Form
                    {...formPropsCreateManyTariffsFromMultiplePowerFields}
                    layout="horizontal"
                    onFinish={(values: RecordValuesInForm | unknown) => {
                        // before sending values we format them.
                        const returnedValuesForm = values as RecordValuesInForm

                        const { power, contractPeriodId, incrementBy, ...rest } = returnedValuesForm

                        /**
                         * We send tariffs witch is an array with a tariffcomponentId and it's price.
                         */
                        //eslint-disable-next-line
                        const tariffs: { tariffComponentId: string; price: number }[] = []

                        Object.keys(rest).map((tariffComponentKey) =>
                            tariffs.push({
                                tariffComponentId: tariffComponentsWithTheirIds[tariffComponentKey],
                                price: rest[tariffComponentKey],
                            }),
                        )

                        const finalValues = {
                            incrementBy,
                            contractPeriodId: contractPeriodId,
                            tariffs: tariffs,
                        }
                        formPropsCreateManyTariffsFromMultiplePowerFields.onFinish?.({
                            ...finalValues,
                        })
                    }}
                >
                    <Form.Item
                        label="Identifiant period"
                        name="contractPeriodId"
                        initialValue={idCreatePeriodFromMultiplePowerFields}
                    >
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                        label="Puissance (kva) incrémenté par"
                        name="incrementBy"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Select
                            options={[
                                {
                                    label: '1',
                                    value: 1,
                                },
                                {
                                    label: '3',
                                    value: 3,
                                },
                            ]}
                        />
                    </Form.Item>

                    {Object.keys(tariffsColumnsFormat)?.map((tariffComponentName) => (
                        <Form.Item
                            key={tariffsColumnsFormat[tariffComponentName].tariffComponentId}
                            label={`${tariffComponentName} (€)`}
                            name={`${tariffComponentName}`}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                        >
                            <Input type={'number'} />
                        </Form.Item>
                    ))}
                </Form>
            </Modal>

            {/* Modal for edit of many tariffs */}
            <Modal {...modalPropsUpdateManyTariffs}>
                <Form
                    {...formPropsUpdateManyTariffs}
                    layout="horizontal"
                    onFinish={(values: RecordValuesInForm | unknown) => {
                        // before sending values we format them.
                        const returnedValuesForm = values as RecordValuesInForm

                        const { power, contractPeriodId, ...rest } = returnedValuesForm

                        /**
                         * We send tariffs witch is an array with a tariffcomponentId and it's price.
                         */
                        //eslint-disable-next-line
                        const tariffs: { tariffComponentId: string; price: number }[] = []

                        Object.keys(rest).map((tariffComponentKey) =>
                            tariffs.push({
                                tariffComponentId: tariffComponentsWithTheirIds[tariffComponentKey],
                                price: rest[tariffComponentKey],
                            }),
                        )

                        const finalValues = {
                            power: power,
                            contractPeriodId: contractPeriodId,
                            tariffs: tariffs,
                        }
                        formPropsUpdateManyTariffs.onFinish?.({
                            ...finalValues,
                        })
                    }}
                >
                    <Form.Item label="Identifiant periode" name="contractPeriodId" initialValue={idUpdatePeriod}>
                        <Input disabled />
                    </Form.Item>

                    <Form.Item
                        label="Puissance (kva)"
                        name="power"
                        initialValue={defaultValuesUpdateManyTariff?.power}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input disabled type={'number'} />
                    </Form.Item>

                    {Object.keys(tariffsColumnsFormat)?.map((tariffComponentName) => (
                        <Form.Item
                            key={tariffsColumnsFormat[tariffComponentName].tariffComponentId}
                            label={`${tariffComponentName} (€)`}
                            name={`${tariffComponentName}`}
                            rules={[
                                {
                                    required: true,
                                },
                            ]}
                            initialValue={defaultValuesUpdateManyTariff?.[tariffComponentName]?.price}
                        >
                            <Input type={'number'} />
                        </Form.Item>
                    ))}
                </Form>
            </Modal>

            {/* Drawer to list tariff components */}
            <Drawer
                title="Liste des composantes lié à ce contrat"
                placement="right"
                onClose={() => setActiveDrawer(false)}
                open={activeDrawer}
                size="large"
            >
                <List
                    resource="tariffComponent"
                    headerProps={{
                        extra: (
                            <CreateButton
                                resource="tariffComponents"
                                onClick={() => {
                                    setActiveDrawer(false)
                                    showModalCreateTariffComponents()
                                }}
                            >
                                Ajouter une Composante
                            </CreateButton>
                        ),
                    }}
                >
                    <Table
                        {...tableProps}
                        pagination={{
                            ...tableProps.pagination,
                            position: ['bottomRight'],
                            size: 'small',
                        }}
                        rowKey="id"
                    >
                        <Table.Column dataIndex="name" title="Nom composante" />
                        <Table.Column dataIndex="startTime" title="Heure Début" />
                        <Table.Column dataIndex="endTime" title="Heure Fin" />
                        <Table.Column<ITariffComponent>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    <EditButton
                                        hideText
                                        size="small"
                                        onClick={() => push(`/supplyContracts/tariffComponent/edit/${record.id}`)}
                                        recordItemId={record.id}
                                    />
                                    <DeleteButton
                                        hideText
                                        resource="tariffComponent"
                                        size="small"
                                        recordItemId={record.id}
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                </List>
            </Drawer>

            {/* Modal for creation of tariff component */}

            <Modal {...modalPropsCreateTariffComponents}>
                <Form {...formPropsCreateTariffComponents} layout="horizontal">
                    <Form.Item
                        label="Nom de la composante"
                        name="name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label="Contrat"
                        name="contractId"
                        initialValue={record?.id}
                    >
                        <Select
                            options={[
                                {
                                    value: record?.id,
                                    label: record?.name,
                                },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Utiliser le compteur heures creuses"
                        name="useMeterOffpeak"
                        initialValue={false}
                        valuePropName="checked"
                    >
                        <Checkbox>Oui</Checkbox>
                    </Form.Item>

                    <Form.Item label="Appliquer aux jours" name="byweekday" initialValue={[]}>
                        <Checkbox.Group options={daysOptions} />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label="Fréquence"
                        name="freq"
                    >
                        <Radio.Group>
                            <Radio value="DAILY"> Quotidienne </Radio>
                            <Radio value="MONTHLY"> Mensuelle </Radio>
                            <Radio value="YEARLY"> Annuelle </Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item label="Type de tariff de la composante ?" name="componentTariffType">
                        <Radio.Group>
                            <Radio value="base"> Base </Radio>
                            <Radio value="hc"> Heure pleine </Radio>
                            <Radio value="hp"> Heure creuse </Radio>
                            <Radio value="jour_bleu"> Jour bleu </Radio>
                            <Radio value="jour_rouge"> Jour rouge </Radio>
                            <Radio value="jour_blanc"> Jour blanc </Radio>
                            <Radio value={null}>Rien</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Heure Début"
                        name="startTime"
                        valuePropName="dateString"
                        normalize={formatTimePickerValue}
                    >
                        <TimePicker showNow={false} format="HH:mm" />
                    </Form.Item>

                    <Form.Item
                        label="Heure Fin"
                        name="endTime"
                        valuePropName="dateString"
                        normalize={formatTimePickerValue}
                    >
                        <TimePicker showNow={false} format="HH:mm" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
