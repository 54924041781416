import React from 'react'
import { Create, useForm } from '@refinedev/antd'
import { Form, Input } from 'antd'

import { ITariffType } from 'src/modules/SupplyContracts/TariffTypes/TariffTypes.d'

/**
 * This function returns a view to create a Tariff Type.
 *
 * @returns View to create a TariffType.
 */
export const CreateTariffTypes = () => {
    const { formProps, saveButtonProps } = useForm<ITariffType>()

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Type du Tarif"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Create>
    )
}
