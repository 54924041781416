import React from 'react'
import { Form, Input } from 'antd'
import { Create, useForm } from '@refinedev/antd'
import { IProvider } from 'src/modules/SupplyContracts/Providers/Providers.d'

/**
 * This function returns a view to create a provider.
 *
 * @returns View to create a provider.
 */
export const CreateProvider = () => {
    const { formProps, saveButtonProps } = useForm<IProvider>()

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Nom fournisseur"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Create>
    )
}
