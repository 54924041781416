import React from 'react'
import { Edit, useForm, ListButton } from '@refinedev/antd'
import { Form, Input } from 'antd'
import { IContractType } from 'src/modules/SupplyContracts/ContractTypes/ContractTypes.d'
import { useParams } from 'react-router-dom'

/**
 * This function returns a view to edit Contract types.
 *
 * @returns View to Edit Contract types.
 */
export const EditContractTypes = () => {
    const { id } = useParams()
    const { formProps, saveButtonProps } = useForm<IContractType>({
        action: 'edit',
        resource: 'contractType',
        id: id,
        meta: {
            operation: 'allContractTypes', // the query operation
            fields: [
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
    })

    return (
        <Edit
            saveButtonProps={saveButtonProps}
            headerProps={{
                title: 'Modifier détails du type de contrat',
                extra: <ListButton resource="contractType" />,
            }}
        >
            <Form
                {...formProps}
                layout="vertical"
                onFinish={(values) =>
                    formProps.onFinish?.({
                        ...values,
                    })
                }
            >
                <Form.Item
                    label="Type de contrat"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
            </Form>
        </Edit>
    )
}
