import React from 'react'
import { Edit, useForm, ListButton } from '@refinedev/antd'
import { Form, Input } from 'antd'
import { IEcoGestPole } from 'src/modules/Advices/EcoGestPoles/EcoGestPoles.d'
import { useParams } from 'react-router-dom'
import { resourceEcoGestPolesName } from 'src/modules/Advices/EcoGestPoles'

/**
 * This function returns a view to edit eco gests tags.
 *
 * @returns View to Edit eco gests tags.
 */
export const EditEcoGestPoles = () => {
    const { id } = useParams()
    const { formProps, saveButtonProps } = useForm<IEcoGestPole>({
        action: 'edit',
        resource: resourceEcoGestPolesName,
        id: id,
        queryOptions: {
            enabled: true,
        },
        meta: {
            operation: 'allEcoGestPoles', // the query operation
            fields: [
                {
                    edges: [
                        {
                            node: ['id', 'name', 'icon'],
                        },
                    ],
                },
            ],
        },
    })

    return (
        <Edit
            saveButtonProps={saveButtonProps}
            headerProps={{
                title: 'Détail du Poste de consomation',
                extra: <ListButton resource="ecoGestPole" />,
            }}
        >
            <Form
                {...formProps}
                layout="vertical"
                onFinish={(values) =>
                    formProps.onFinish?.({
                        ...values,
                    })
                }
            >
                <Form.Item label="Titre" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="Url de l'icône" name="icon">
                    <Input />
                </Form.Item>
            </Form>
        </Edit>
    )
}
