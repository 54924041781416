import { EcoGestsResourceConfig, ecoGestsRoutes } from 'src/modules/Advices/EcoGests/EcoGestsConfig'
import { EcoGestPolesResourceConfig, ecoGestPolesRoutes } from 'src/modules/Advices/EcoGestPoles/EcoGestPolesConfig'
import { AdvicesMenuConfigName } from 'src/configs'
import { EcoGestRoomsResourceConfig, ecoGestRoomsRoutes } from 'src/modules/Advices/EcoGestRooms/EcoGestRoomsConfig'
import { RouteProps } from 'react-router-dom'

/**
 * AdvicesConfig.
 */
export const AdvicesConfig = [
    {
        name: AdvicesMenuConfigName,
        meta: {
            label: 'Conseils',
        },
    },
    { ...EcoGestsResourceConfig },
    { ...EcoGestPolesResourceConfig },
    { ...EcoGestRoomsResourceConfig },
]

/**
 * Advices Routes.
 */
export const AdvicesRoutes: RouteProps[] = [...ecoGestsRoutes, ...ecoGestPolesRoutes, ...ecoGestRoomsRoutes]
