import React from 'react'
import { Edit, useForm, ListButton } from '@refinedev/antd'
import { ITariffComponent } from 'src/modules/SupplyContracts/TariffComponents/TariffComponents.d'
import { daysOptions } from 'src/modules/SupplyContracts/TariffComponents/common'
import { TimePicker, Form, Input, Checkbox, Radio } from 'antd'
import { useParams } from 'react-router-dom'
import { convertTimeToDate, formatTimePickerValue } from 'src/modules/utils'

/**
 * This function returns a view to edit tariff component.
 *
 * @returns View to Edit tariff component.
 */
export const EditTariffComponents = () => {
    const { id } = useParams()
    const { formProps, saveButtonProps, queryResult } = useForm<ITariffComponent>({
        action: 'edit',
        resource: 'tariffComponent',
        id: id,
        meta: {
            operation: 'allTariffComponents', // the query operation to fill the form
            fields: [
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'name',
                                'useMeterOffpeak',
                                'startTime',
                                'endTime',
                                'byweekday',
                                'freq',
                                'componentTariffType',
                                {
                                    tariffType: ['name'],
                                },
                                {
                                    contract: ['name'],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    })

    const defaultUseMeterOffpeak = queryResult?.data?.data?.useMeterOffpeak
    const defaultByweekday = queryResult?.data?.data?.byweekday
    const defaultFreq = queryResult?.data?.data?.freq
    const defaultComponentTariffType = queryResult?.data?.data?.componentTariffType

    return (
        <Edit
            saveButtonProps={saveButtonProps}
            headerProps={{
                title: 'Modifier détails composoante de tarif',
                extra: <ListButton resource="tariffComponent" />,
            }}
        >
            <Form
                {...formProps}
                layout="horizontal"
                onFinish={(values) =>
                    formProps.onFinish?.({
                        ...values,
                    })
                }
            >
                <Form.Item
                    label="Nom de la composante"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label="Utiliser le compteur heures creuses"
                    name="useMeterOffpeak"
                    initialValue={defaultUseMeterOffpeak}
                    valuePropName="checked"
                >
                    <Checkbox>Oui</Checkbox>
                </Form.Item>

                <Form.Item label="Appliquer aux jours" name="byweekday" initialValue={defaultByweekday}>
                    <Checkbox.Group options={daysOptions} />
                </Form.Item>
                <Form.Item
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label="Fréquence"
                    name="freq"
                    initialValue={defaultFreq}
                >
                    <Radio.Group>
                        <Radio value="DAILY"> Quotidienne </Radio>
                        <Radio value="MONTHLY"> Mensuelle </Radio>
                        <Radio value="YEARLY"> Annuelle </Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Type de tariff de la composante ?"
                    name="componentTariffType"
                    initialValue={defaultComponentTariffType}
                >
                    <Radio.Group>
                        <Radio value="base"> Base </Radio>
                        <Radio value="hp"> Heure pleine </Radio>
                        <Radio value="hc"> Heure creuse </Radio>
                        <Radio value="jour_bleu"> Jour bleu </Radio>
                        <Radio value="jour_rouge"> Jour rouge </Radio>
                        <Radio value="jour_blanc"> Jour blanc </Radio>
                        <Radio value={null}>Rien</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label="Heure Début"
                    name="startTime"
                    valuePropName="dateString" // so it can take the value as dateString
                    normalize={formatTimePickerValue}
                >
                    <TimePicker
                        showNow={false}
                        format="HH:mm"
                        defaultValue={convertTimeToDate(queryResult?.data?.data?.startTime)}
                    />
                </Form.Item>

                <Form.Item
                    label="Heure Fin"
                    name="endTime"
                    valuePropName="dateString"
                    normalize={formatTimePickerValue}
                >
                    <TimePicker
                        showNow={false}
                        format="HH:mm"
                        defaultValue={convertTimeToDate(queryResult?.data?.data?.endTime)}
                    />
                </Form.Item>
            </Form>
        </Edit>
    )
}
