import React from 'react'
import { Create, useForm, useSelect } from '@refinedev/antd'
import { Form, Input, Select } from 'antd'

import { IContractType } from 'src/modules/SupplyContracts/ContractTypes/ContractTypes.d'
import { IContract } from 'src/modules/SupplyContracts/Contracts/Contracts.d'
import { ITariffType } from 'src/modules/SupplyContracts/TariffTypes/TariffTypes.d'
import { ICommercialOffer } from 'src/modules/SupplyContracts/CommercialOffers/CommercialOffers.d'

/**
 * This function returns a view to create a Contract.
 *
 * @returns View to create a Contract.
 */
export const CreateContracts = () => {
    const { formProps, saveButtonProps } = useForm<IContract>()

    const { selectProps: selectedPropsCommercialOffer } = useSelect<ICommercialOffer>({
        resource: 'commercialOffer',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allCommercialOffers',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
        sorters: [
            {
                field: "name",
                order: "asc"
            }
        ]
    })

    const { selectProps: selectedPropsContractType } = useSelect<IContractType>({
        resource: 'contractType',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allContractTypes',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
        sorters: [
            {
                field: "name",
                order: "asc"
            }
        ]
    })

    const { selectProps: selectedPropsTariffType } = useSelect<ITariffType>({
        resource: 'tariffType',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allTariffTypes',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
        sorters: [
            {
                field: "name",
                order: "asc"
            }
        ]
    })

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="horizontal">
                <Form.Item
                    label="Nom du contrat"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label="Offre associée"
                    name="commercialOfferId"
                >
                    <Select {...selectedPropsCommercialOffer} />
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label="Type de contrat associé"
                    name="contractTypeId"
                >
                    <Select {...selectedPropsContractType} />
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                    label="Type de tarif associé"
                    name="tariffTypeId"
                >
                    <Select {...selectedPropsTariffType} />
                </Form.Item>
            </Form>
        </Create>
    )
}
