import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import {
    EditTariffComponents,
    ListTariffComponents,
    CreateTariffComponents,
} from 'src/modules/SupplyContracts/TariffComponents'
import { SupplyContractsMenuConfigName } from 'src/configs'

import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'

/**
 * Resource TariffComponents Name.
 */
export const resourceTariffComponentsName = 'tariffComponent'

const tariffComponentsUrls = getResourceUrlsFromResourceName(
    resourceTariffComponentsName,
    SupplyContractsMenuConfigName,
)

/**
 * TariffComponents Resource config.
 */
export const TariffComponentsResourceConfig: IRefineResourceConfig = {
    name: resourceTariffComponentsName,
    meta: {
        parent: SupplyContractsMenuConfigName,
        label: 'Composantes des Tarifs',
    },
    ...tariffComponentsUrls,
}

/**
 * TariffComponentsRoutes.
 */
export const tariffComponentsRoutes: RouteProps[] = [
    {
        path: tariffComponentsUrls.list,
        Component: ListTariffComponents,
    },
    {
        path: tariffComponentsUrls.create,
        Component: CreateTariffComponents,
    },
    {
        path: tariffComponentsUrls.edit,
        Component: EditTariffComponents,
    }
]
