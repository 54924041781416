import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import {
    EditEcoGestPoles,
    ListEcoGestPoles,
    ShowEcoGestPoles,
    CreateEcoGestPoles,
    resourceEcoGestPolesName,
} from 'src/modules/Advices/EcoGestPoles'
import { AdvicesMenuConfigName } from 'src/configs'

import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'

const ecoGestPolesUrls = getResourceUrlsFromResourceName(resourceEcoGestPolesName, AdvicesMenuConfigName)

/**
 * EcoGestPoles Resource config.
 */
export const EcoGestPolesResourceConfig: IRefineResourceConfig = {
    name: resourceEcoGestPolesName,
    meta: {
        parent: AdvicesMenuConfigName,
        label: 'Pole de Consommation',
    },
    ...ecoGestPolesUrls,
}

/**
 * EcoGestPolesRoutes.
 */
export const ecoGestPolesRoutes: RouteProps[] = [
    {
        path: ecoGestPolesUrls.list,
        Component: ListEcoGestPoles,
    },
    {
        path: ecoGestPolesUrls.create,
        Component: CreateEcoGestPoles,
    },
    {
        path: ecoGestPolesUrls.edit,
        Component: EditEcoGestPoles,
    },
    {
        path: ecoGestPolesUrls.show,
        Component: ShowEcoGestPoles,
    },
]
