import React from 'react'
import { DeleteButton, EditButton, List, ShowButton, useTable } from '@refinedev/antd'
import { Tag, Space, Table } from 'antd'
import { IEcoGestRoom } from 'src/modules/Advices/EcoGestRooms/EcoGestRooms.d'
import { IEcoGestPole } from 'src/modules/Advices/EcoGestPoles/EcoGestPoles.d'
import { IEcoGest } from 'src/modules/Advices/EcoGests/EcoGests.d'
import 'src/modules/Advices/EcoGests/EcoGests.css'
import { StarOutlined } from '@ant-design/icons'

/**
 * This function returns a list of ecoGests.
 *
 * @returns List of ecoGests.
 */
export const ListEcoGests = () => {
    const { tableProps } = useTable<IEcoGest>({
        meta: {
            operation: 'allEcoGests',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'title',
                                'description',
                                'percentageSaved',
                                'urlIcon',
                                'highlighted',
                                'infos',
                                'equipments',
                                'energies',
                                'homeConfigurations',
                                {
                                    poleTags: [
                                        {
                                            edges: [
                                                {
                                                    node: ['name', 'id', 'icon'],
                                                },
                                            ],
                                        },
                                    ],
                                },
                                {
                                    roomTags: [
                                        {
                                            edges: [
                                                {
                                                    node: ['name', 'id', 'icon'],
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        sorters: {
            initial: [
                {
                    field: 'id',
                    order: 'asc',
                },
            ],
        },
    })

    return (
        <div>
            <List breadcrumb={null}>
                <Table
                    {...tableProps}
                    pagination={{
                        ...tableProps.pagination,
                        position: ['bottomRight'],
                        size: 'small',
                    }}
                    rowKey="id"
                >
                    <Table.Column dataIndex="title" title="Titre" ellipsis className="ecoGest-infos-column" />
                    <Table.Column
                        dataIndex="highlighted"
                        title="A la une"
                        ellipsis
                        className="ecoGest-infos-column"
                        render={(value) => (
                            <div className="flex gap-2">
                                {value && (
                                    <Tag color="gold">
                                        <StarOutlined />
                                    </Tag>
                                )}
                            </div>
                        )}
                    />
                    <Table.Column
                        dataIndex="description"
                        title="Description"
                        ellipsis
                        className="ecoGest-infos-column"
                    />
                    <Table.Column
                        dataIndex="percentageSaved"
                        title="% Economie"
                        ellipsis
                        className="ecoGest-infos-column"
                    />
                    <Table.Column dataIndex="infos" title="Informations" className="ecoGest-infos-column" ellipsis />
                    <Table.Column
                        dataIndex={['poleTags', 'edges']}
                        className="ecoGest-infos-column"
                        ellipsis
                        title="Postes"
                        render={(edges) => (
                            <div className="flex gap-2">
                                {edges.map(
                                    // eslint-disable-next-line jsdoc/require-jsdoc
                                    ({ node }: { node: IEcoGestPole }) => (
                                        <Tag color="gold" key={node.id}>
                                            {node.name}
                                        </Tag>
                                    ),
                                )}
                            </div>
                        )}
                    />
                    <Table.Column
                        dataIndex={['roomTags', 'edges']}
                        className="ecoGest-infos-column"
                        ellipsis
                        title="Pièces"
                        render={(edges) => (
                            <div className="flex gap-2">
                                {edges.map(
                                    // eslint-disable-next-line jsdoc/require-jsdoc
                                    ({ node }: { node: IEcoGestRoom }) => (
                                        <Tag color="green" key={node.id}>
                                            {node.name}
                                        </Tag>
                                    ),
                                )}
                            </div>
                        )}
                    />
                    <Table.Column<IEcoGest>
                        title="Actions"
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton hideText size="small" recordItemId={record.id} />
                                <ShowButton hideText size="small" recordItemId={record.id} />
                                <DeleteButton hideText size="small" recordItemId={record.id} />
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </div>
    )
}
