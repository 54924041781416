import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import {
    EditEcoGestRooms,
    ListEcoGestRooms,
    ShowEcoGestRooms,
    CreateEcoGestRooms,
} from 'src/modules/Advices/EcoGestRooms'
import { AdvicesMenuConfigName } from 'src/configs'

import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'

/**
 * Resource EcoGestRooms Name.
 */
export const resourceEcoGestRoomsName = 'ecoGestRoom'

const ecoGestRoomsUrls = getResourceUrlsFromResourceName(resourceEcoGestRoomsName, AdvicesMenuConfigName)

/**
 * EcoGestRooms Resource config.
 */
export const EcoGestRoomsResourceConfig: IRefineResourceConfig = {
    name: resourceEcoGestRoomsName,
    meta: {
        parent: AdvicesMenuConfigName,
        label: 'Pièces',
    },
    ...ecoGestRoomsUrls,
}

/**
 * EcoGestRoomsRoutes.
 */
export const ecoGestRoomsRoutes: RouteProps[] = [
    {
        path: ecoGestRoomsUrls.list,
        Component: ListEcoGestRooms,
    },
    {
        path: ecoGestRoomsUrls.create,
        Component: CreateEcoGestRooms,
    },
    {
        path: ecoGestRoomsUrls.edit,
        Component: EditEcoGestRooms,
    },
    {
        path: ecoGestRoomsUrls.show,
        Component: ShowEcoGestRooms,
    },
]
