import React from 'react'
import { useSelect } from '@refinedev/antd'
import { Select, SelectProps } from 'antd'
import { IEcoGestPole } from 'src/modules/Advices/EcoGestPoles/EcoGestPoles.d'

/**
 * Reusable Component Select for EcoGestTags.
 *
 * @param props Select EcoGestTags Props.
 * @returns SelectEcoGestPoles Component.
 */
export const SelectEcoGestPoles = (props: SelectProps) => {
    const { selectProps } = useSelect<IEcoGestPole>({
        meta: {
            variables: {
                limit: 1000,
            },
            operation: 'allEcoGestPoles',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name', 'icon'],
                        },
                    ],
                },
            ],
        },
        optionLabel: 'name',
        resource: 'EcoGestPole',
        onSearch:
            /**
             * Search input value for EcoGestTags field.
             *
             * @param inputValue Input Value of ecoGestTags field.
             * @returns The EcoGestTag related to the search.
             */
            (inputValue) => [
                {
                    field: 'name',
                    operator: 'eq',
                    value: inputValue,
                },
            ],
    })

    return <Select {...selectProps} mode="multiple" {...props} />
}
