import React from 'react'
import { Create, useForm } from '@refinedev/antd'
import { Form, Input } from 'antd'
import { IEcoGestPole } from 'src/modules/Advices/EcoGestPoles/EcoGestPoles.d'

/**
 * This function returns a view to create one EcoGestPole.
 *
 * @returns View to create one EcoGestPole.
 */
export const CreateEcoGestPoles = () => {
    const { formProps, saveButtonProps } = useForm<IEcoGestPole>()

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item label="Titre" name="name">
                    <Input />
                </Form.Item>
                <Form.Item label="Url de l'icône" name="icon">
                    <Input />
                </Form.Item>
            </Form>
        </Create>
    )
}
