import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import { ListStores, EditStores, CreateStores } from 'src/modules/Logistics/Stores'
import { LogisticMenuConfigName } from 'src/configs'

import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'

/**
 * Resource Stores Name.
 */
export const resourceStoresName = 'store'

const storesUrls = getResourceUrlsFromResourceName(resourceStoresName, LogisticMenuConfigName)

/**
 * Stores Resource config.
 */
export const StoresResourceConfig: IRefineResourceConfig = {
    name: resourceStoresName,
    meta: {
        parent: LogisticMenuConfigName,
        label: 'Gestion des Stores',
    },
    list: storesUrls.list,
    create: storesUrls.create,
    edit: storesUrls.edit,
}

/**
 * StoresRoutes.
 */
export const storesRoutes: RouteProps[] = [
    {
        path: storesUrls.list,
        Component: ListStores,
    },
    {
        path: storesUrls.create,
        Component: CreateStores,
    },
    {
        path: storesUrls.edit,
        Component: EditStores,
    },
]
