import React from 'react'
import { Create, useForm, useSelect } from '@refinedev/antd'
import { Form, Input, Select } from 'antd'
import { ICommercialOffer } from 'src/modules/SupplyContracts/CommercialOffers/CommercialOffers.d'
import { IProvider } from 'src/modules/SupplyContracts/Providers/Providers.d'

/**
 * This function returns a view to create a Commercial Offer.
 *
 * @returns View to create a Commercial Offer.
 */
export const CreateCommercialOffers = () => {
    const { formProps, saveButtonProps } = useForm<ICommercialOffer>()

    const { selectProps } = useSelect<IProvider>({
        resource: 'provider',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allProviders',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
    })

    return (
        <Create saveButtonProps={saveButtonProps}>
            <Form {...formProps} layout="vertical">
                <Form.Item
                    label="Nom de l'offre"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Le Fournisseur"
                    name="providerId"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Select {...selectProps} />
                </Form.Item>
            </Form>
        </Create>
    )
}
