import React from 'react'
import { DeleteButton, EditButton, List, ShowButton, useTable } from '@refinedev/antd'
import { Space, Table } from 'antd'
import { IEcoGestPole } from 'src/modules/Advices/EcoGestPoles/EcoGestPoles.d'
import 'src/modules/Advices/EcoGests/EcoGests.css'

/**
 * This function returns a list of EcoGestPoles.
 *
 * @returns List of EcoGestPoles.
 */
export const ListEcoGestPoles = () => {
    const { tableProps } = useTable<IEcoGestPole>({
        meta: {
            operation: 'allEcoGestPoles',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name', 'icon'],
                        },
                    ],
                },
            ],
        },
        sorters: {
            initial: [
                {
                    field: 'id',
                    order: 'asc',
                },
            ],
        },
    })

    return (
        <div>
            <List breadcrumb={null}>
                <Table
                    {...tableProps}
                    pagination={{
                        ...tableProps.pagination,
                        position: ['bottomRight'],
                        size: 'small',
                    }}
                    rowKey="id"
                >
                    <Table.Column dataIndex="name" title="Titre" sorter ellipsis className="ecoGest-infos-column" />
                    <Table.Column dataIndex="icon" title="Url Icône" sorter ellipsis className="ecoGest-infos-column" />
                    <Table.Column<IEcoGestPole>
                        title="Actions"
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton hideText size="small" recordItemId={record.id} />
                                <ShowButton hideText size="small" recordItemId={record.id} />
                                <DeleteButton hideText size="small" recordItemId={record.id} />
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </div>
    )
}
