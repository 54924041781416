import React, { useState } from 'react'
import { Form, Input, Modal, Select, Checkbox, Radio, TimePicker, Drawer, Table, Space } from 'antd'
import {
    Edit,
    List,
    useForm,
    ListButton,
    CreateButton,
    useModalForm,
    useTable,
    EditButton,
    ShowButton,
    DeleteButton,
} from '@refinedev/antd'
import { ITariffComponent } from 'src/modules/SupplyContracts/TariffComponents/TariffComponents.d'
import { ITariffType } from 'src/modules/SupplyContracts/TariffTypes/TariffTypes.d'
import { daysOptions } from 'src/modules/SupplyContracts/TariffComponents/common'
import { useNavigation } from '@refinedev/core'
import { useParams } from 'react-router-dom'

/**
 * This function returns a view to edit tariff types.
 *
 * @returns View to Edit tariff types.
 */
export const EditTariffTypes = () => {
    const { push } = useNavigation()
    const { id } = useParams()

    const { formProps, saveButtonProps, queryResult } = useForm<ITariffType>({
        action: 'edit',
        resource: 'tariffType',
        id: id,
        queryOptions: {
            enabled: true,
        },
        meta: {
            operation: 'allTariffTypes', // the query operation
            fields: [
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
    })

    const [activeDrawer, setActiveDrawer] = useState(false)

    const { tableProps, tableQueryResult } = useTable<ITariffComponent>({
        resource: 'tariffComponent',
        meta: {
            operation: 'allTariffComponents',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name', 'startTime', 'endTime'],
                        },
                    ],
                },
            ],
        },
        filters: {
            initial: [
                {
                    field: 'tariffTypeId',
                    operator: 'eq',
                    value: id,
                },
            ],
        },
        sorters: {
            initial: [
                {
                    field: 'id',
                    order: 'asc',
                },
            ],
        },
    })

    const {
        modalProps: modalPropsCreateTariffComponents,
        formProps: formPropsCreateTariffComponents,
        show: showModalCreateTariffComponents,
    } = useModalForm<ITariffComponent>({
        action: 'create',
        resource: 'TariffComponents',
        autoSubmitClose: true,
        redirect: false,
        autoResetForm: true,
        meta: {
            fields: [{ tariffComponent: ['id'] }],
        },
        //eslint-disable-next-line
        onMutationSuccess(data, variables, context) {
            tableQueryResult?.refetch()
            setActiveDrawer(true)
        },
    })

    return (
        <>
            <Edit
                saveButtonProps={saveButtonProps}
                headerProps={{
                    title: 'Modifier détails du type de tarifs',
                    extra: (
                        <>
                            <ListButton resource="tariffType" />
                            <ListButton
                                type="primary"
                                resource="tariffComponents"
                                onClick={() => setActiveDrawer(true)}
                            >
                                Lister les Composantes
                            </ListButton>
                        </>
                    ),
                }}
            >
                <Form
                    {...formProps}
                    layout="vertical"
                    onFinish={(values) =>
                        formProps.onFinish?.({
                            ...values,
                        })
                    }
                >
                    <Form.Item
                        label="Type du tarif"
                        name="name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Form>
            </Edit>

            {/* Drawer to list tariff components */}

            <Drawer
                title="Liste des composantes lié à ce tarif"
                placement="right"
                onClose={() => setActiveDrawer(false)}
                open={activeDrawer}
                size="large"
            >
                <List
                    resource="tariffComponent"
                    headerProps={{
                        extra: (
                            <CreateButton
                                resource="tariffComponents"
                                onClick={() => {
                                    setActiveDrawer(false)
                                    showModalCreateTariffComponents()
                                }}
                            >
                                Ajouter une Composante
                            </CreateButton>
                        ),
                    }}
                >
                    <Table
                        {...tableProps}
                        pagination={{
                            ...tableProps.pagination,
                            position: ['bottomRight'],
                            size: 'small',
                        }}
                        rowKey="id"
                    >
                        <Table.Column dataIndex="name" title="Nom Offre" />
                        <Table.Column dataIndex="startTime" title="Heure Début" />
                        <Table.Column dataIndex="endTime" title="Heure Fin" />
                        <Table.Column<ITariffComponent>
                            title="Actions"
                            dataIndex="actions"
                            render={(_, record) => (
                                <Space>
                                    <EditButton
                                        hideText
                                        size="small"
                                        onClick={() => push(`/supplyContracts/tariffComponent/edit/${record.id}`)}
                                        recordItemId={record.id}
                                    />
                                    <ShowButton
                                        hideText
                                        size="small"
                                        onClick={() => push(`/supplyContracts/tariffComponent/show/${record.id}`)}
                                        recordItemId={record.id}
                                    />
                                    <DeleteButton
                                        hideText
                                        resource="tariffComponent"
                                        size="small"
                                        recordItemId={record.id}
                                    />
                                </Space>
                            )}
                        />
                    </Table>
                </List>
            </Drawer>

            {/* Modal for creation of tariff component */}

            <Modal {...modalPropsCreateTariffComponents}>
                <Form {...formPropsCreateTariffComponents} layout="horizontal">
                    <Form.Item
                        label="Nom de la composante"
                        name="name"
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label="Type de Tarif"
                        name="tariffTypeId"
                        initialValue={queryResult?.data?.data.id}
                    >
                        <Select
                            disabled
                            options={[
                                {
                                    value: queryResult?.data?.data.id,
                                    label: queryResult?.data?.data.name,
                                },
                            ]}
                        />
                    </Form.Item>

                    <Form.Item
                        label="Utiliser le compteur heures creuses"
                        name="useMeterOffpeak"
                        initialValue={false}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        valuePropName="checked"
                    >
                        <Checkbox>Oui</Checkbox>
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label="Appliquer aux jours"
                        name="byweekday"
                    >
                        <Checkbox.Group options={daysOptions} />
                    </Form.Item>

                    <Form.Item
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                        label="Fréquence"
                        name="freq"
                    >
                        <Radio.Group>
                            <Radio value="DAILY"> Quotidienne </Radio>
                            <Radio value="MONTHLY"> Mensuelle </Radio>
                            <Radio value="YEARLY"> Annuelle </Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label="Heure Début"
                        name="startTime"
                        valuePropName="dateString"
                        normalize={(value) => value?.add(1, 'hours')?.toISOString().split('T')[1].split('.')[0]}
                    >
                        <TimePicker showNow={false} format="HH:mm" />
                    </Form.Item>

                    <Form.Item
                        label="Heure Fin"
                        name="endTime"
                        valuePropName="dateString"
                        normalize={(value) => value?.add(1, 'hours')?.toISOString().split('T')[1].split('.')[0]}
                    >
                        <TimePicker showNow={false} format="HH:mm" />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
