import React from 'react'
import { useForm, useSelect } from '@refinedev/antd'
import { Form, Select, DatePicker, Button, Row, Col } from 'antd'
import {
    INetworks,
    nrlinkWidgetFiltersFormValuesType,
    NrlinkWidgetFilterFormProps,
} from 'src/modules/NrlinksManagement/NrlinkWidgets/NrlinkWidgets'
const { RangePicker } = DatePicker

/**
 * NrlinkWidgetFilterForm Component.
 *
 * @param props N/A.
 * @param props.isLoadingInProgress When form is Loading.
 * @param props.onSubmit On Submit handler.
 * @returns NrlinkWidgetFilterForm Component.
 */
const NrlinkWidgetFilterForm = ({ isLoadingInProgress, onSubmit }: NrlinkWidgetFilterFormProps) => {
    const { formProps, saveButtonProps } = useForm()

    const { selectProps } = useSelect<INetworks>({
        dataProviderName: 'networksDataProvider',
        meta: {
            variables: {
                limit: 1000,
            },
            operation: 'allNetworks',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['name', 'id'],
                        },
                    ],
                },
            ],
        },
        optionValue: 'name',
        resource: 'Network',
        onSearch:
            /**
             * Search input value for Network.
             *
             * @param inputValue Input Value of Network.
             * @returns The EcoGestTag related to the search.
             */
            (inputValue) => [
                {
                    field: 'name',
                    operator: 'contains',
                    value: inputValue,
                },
            ],
    })

    return (
        <Form
            {...formProps}
            initialValues={{
                title: undefined,
                interval: undefined,
            }}
            layout="inline"
            onFinish={(values: nrlinkWidgetFiltersFormValuesType) => {
                onSubmit({
                    networkName: values.networkName,
                    interval: values.interval
                        ? { start: values.interval[0].toISOString(), end: values.interval[1]?.toISOString() }
                        : undefined,
                })
            }}
        >
            <Row style={{ width: '100%' }}>
                <Col sm={24} lg={18} style={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginBottom: '8px' }}>
                    <Form.Item label="Nom du réseau" name="networkName">
                        <Select {...selectProps} style={{ minWidth: '150px', maxWidth: '200px' }} allowClear />
                    </Form.Item>

                    <Form.Item label="Intervalle" name="interval" style={{ flexGrow: 1 }}>
                        <RangePicker
                            showTime
                            format="YYYY/MM/DD HH:mm:ss"
                            placeholder={['Début', 'Fin']}
                            style={{ width: '100%' }}
                        />
                    </Form.Item>
                </Col>
                <Col sm={6} lg={6} style={{ display: 'flex', gap: '16px' }}>
                    <Button onClick={() => formProps.form?.resetFields()}>Annuler</Button>
                    <Button {...saveButtonProps} type="primary" loading={isLoadingInProgress}>
                        Filtrer
                    </Button>
                </Col>
            </Row>
        </Form>
    )
}

export default NrlinkWidgetFilterForm
