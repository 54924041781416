import React from 'react'
import { RefreshButton, Show } from '@refinedev/antd'
import { Typography } from 'antd'
import { IEcoGestRoom } from 'src/modules/Advices/EcoGestRooms/EcoGestRooms.d'
import { useShow } from '@refinedev/core'
import { useParams } from 'react-router-dom'

/**
 * This function returns a view of one EcoGestRoom.
 *
 * @returns View to show details of one EcoGestRoom.
 */
export const ShowEcoGestRooms = () => {
    const { id } = useParams()
    const { Title, Text } = Typography

    const { queryResult } = useShow<IEcoGestRoom>({
        resource: 'EcoGestRoom',
        id: id,
        meta: {
            operation: 'allEcoGestRooms', // the query operation
            fields: [
                {
                    edges: [
                        {
                            node: ['id', 'name', 'icon'],
                        },
                    ],
                },
            ],
        },
    })
    const { data, isLoading } = queryResult
    const record = data?.data

    return (
        <Show
            breadcrumb={null}
            isLoading={isLoading}
            title="Détail de la pièce"
            headerProps={{
                extra: <RefreshButton onClick={() => queryResult.refetch()} />,
                subTitle: record?.name,
            }}
        >
            <Title level={5}>Titre</Title>
            <Text>{record?.name}</Text>
            <Title level={5}>{"Url de l'icône"}</Title>
            <Text>{record?.icon}</Text>
        </Show>
    )
}
