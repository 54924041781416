import React from 'react'
import { useSelect } from '@refinedev/antd'
import { Select, SelectProps } from 'antd'
import { IEcoGestRoom } from 'src/modules/Advices/EcoGestRooms/EcoGestRooms.d'

/**
 * Reusable Component Select for EcoGestRoom.
 *
 * @param props Select EcoGestRoom Props.
 * @returns SelectEcoGestRooms Component.
 */
export const SelectEcoGestRooms = (props: SelectProps) => {
    const { selectProps } = useSelect<IEcoGestRoom>({
        meta: {
            variables: {
                limit: 1000,
            },
            operation: 'allEcoGestRooms',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name', 'icon'],
                        },
                    ],
                },
            ],
        },
        optionLabel: 'name',
        resource: 'EcoGestRoom',
        onSearch:
            /**
             * Search input value for EcoGestRoom field.
             *
             * @param inputValue Input Value of EcoGestRoom field.
             * @returns The EcoGestTag related to the search.
             */
            (inputValue) => [
                {
                    field: 'name',
                    operator: 'eq',
                    value: inputValue,
                },
            ],
    })

    return <Select {...selectProps} mode="multiple" {...props} />
}
