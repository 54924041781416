import React from 'react'
import { Edit, useForm, ListButton, useSelect } from '@refinedev/antd'
import { Form, Input, Select } from 'antd'
import { ICommercialOffer } from 'src/modules/SupplyContracts/CommercialOffers/CommercialOffers.d'
import { IProvider } from 'src/modules/SupplyContracts/Providers/Providers.d'
import { useParams } from 'react-router-dom'
import Checkbox from 'antd/lib/checkbox'

/**
 * This function returns a view to edit Commercial Offers.
 *
 * @returns View to Edit Commercial Offers.
 */
export const EditCommercialOffers = () => {
    const { id } = useParams()
    const { formProps, saveButtonProps, queryResult } = useForm<ICommercialOffer>({
        action: 'edit',
        resource: 'commercialOffer',
        id: id,
        meta: {
            operation: 'allCommercialOffers', // the query operation to fill the form
            fields: [
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'name',
                                {
                                    provider: ['id', 'name'],
                                },
                                'isDeprecated',
                            ],
                        },
                    ],
                },
            ],
        },
    })

    const defaultProviderValue = queryResult?.data?.data?.provider?.id

    const { selectProps } = useSelect<IProvider>({
        resource: 'provider',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 50,
        meta: {
            operation: 'allProviders',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['id', 'name'],
                        },
                    ],
                },
            ],
        },
    })

    return (
        <Edit
            saveButtonProps={saveButtonProps}
            headerProps={{
                title: "Modifier les détails de l'offre",
                extra: <ListButton resource="commercialOffer" />,
            }}
        >
            <Form
                {...formProps}
                layout="vertical"
                onFinish={(values) =>
                    formProps.onFinish?.({
                        ...values,
                    })
                }
            >
                <Form.Item
                    label="Nom de l'offre"
                    name="name"
                    rules={[
                        {
                            required: true,
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item label="Le Fournisseur" name="providerId" initialValue={defaultProviderValue}>
                    <Select {...selectProps} />
                </Form.Item>
                <Form.Item label="Obselète" name="isDeprecated" valuePropName="checked">
                    <Checkbox />
                </Form.Item>
            </Form>
        </Edit>
    )
}
