import React, { useState } from 'react'
import { DeleteButton, EditButton, List, useSelect, useTable } from '@refinedev/antd'
import { Select, Space, Table } from 'antd'
import { IContract } from 'src/modules/SupplyContracts/Contracts/Contracts.d'
import { LabelInValueType, RawValueType } from 'src/modules/utils/commonTypes'
import { IContractType } from '../ContractTypes/ContractTypes'
import { ICommercialOffer } from '../CommercialOffers/CommercialOffers'
import { ITariffType } from '../TariffTypes/TariffTypes'

/**
 * This function returns a list of contracts.
 *
 * @returns List of contracts.
 */
export const ListContracts = () => {
    const [commercialOfferId, setCommercialOfferId] = useState<string | undefined>(undefined)
    const [contractTypeId, setContractTypeId] = useState<string | undefined>(undefined)
    const [tariffTypeId, setTariffTypeId] = useState<string | undefined>(undefined)

    const { selectProps: commercialOfferSelectProps } = useSelect<ICommercialOffer>({
        resource: 'commercialOffer',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allCommercialOffers',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['name', 'id'],
                        },
                    ],
                },
            ],
        },
        onSearch:
            /**
             * Search input value for ressource.
             *
             * @param inputValue IhdGuid Value of ressource.
             * @returns The ressources related to the search.
             */
            (inputValue) => [
                {
                    field: 'name',
                    operator: 'contains',
                    value: inputValue,
                },
            ],
    })

    const { selectProps: contractTypeSelectProps } = useSelect<IContractType>({
        resource: 'contractType',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allContractTypes',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['name', 'id'],
                        },
                    ],
                },
            ],
        },
        onSearch:
            /**
             * Search input value for ressource.
             *
             * @param inputValue IhdGuid Value of ressource.
             * @returns The ressources related to the search.
             */
            (inputValue) => [
                {
                    field: 'name',
                    operator: 'contains',
                    value: inputValue,
                },
            ],
    })

    const { selectProps: tariffTypeSelectProps } = useSelect<ITariffType>({
        resource: 'tariffType',
        optionLabel: 'name',
        optionValue: 'id',
        fetchSize: 100,
        meta: {
            operation: 'allTariffTypes',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: ['name', 'id'],
                        },
                    ],
                },
            ],
        },
        onSearch:
            /**
             * Search input value for ressource.
             *
             * @param inputValue IhdGuid Value of ressource.
             * @returns The ressources related to the search.
             */
            (inputValue) => [
                {
                    field: 'name',
                    operator: 'contains',
                    value: inputValue,
                },
            ],
    })

    const { tableProps } = useTable<IContract>({
        meta: {
            operation: 'allContracts',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'name',
                                {
                                    commercialOffer: ['name'],
                                },
                                {
                                    contractType: ['name'],
                                },
                                {
                                    tariffType: ['name'],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        permanentFilter: [
            {
                field: 'commercialOfferId',
                operator: 'eq',
                value: commercialOfferId
            },
            {
                field: 'contractTypeId',
                operator: 'eq',
                value: contractTypeId
            },
            {
                field: 'tariffTypeId',
                operator: 'eq',
                value: tariffTypeId
            }
        ],
        sorters: {
            initial: [
                {
                    field: 'id',
                    order: 'asc',
                },
            ],
        },
    })

    return (
        <div>
            <List breadcrumb={null}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        marginBottom: '15px'
                    }}
                >
                    <Select
                        {...commercialOfferSelectProps}
                        placeholder="Nom de l'offre"
                        allowClear
                        style={{marginRight: "60px", width: "250px" }}
                        onSelect={(value: RawValueType | LabelInValueType) => {
                            typeof value === 'string' && setCommercialOfferId(value)
                        }}
                        onClear={() => setCommercialOfferId(undefined)}
                    />
                    <Select
                        {...contractTypeSelectProps}
                        placeholder="Nom du type de contrat"
                        allowClear
                        style={{marginRight: "60px", width: "250px" }}
                        onSelect={(value: RawValueType | LabelInValueType) => {
                            typeof value === 'string' && setContractTypeId(value)
                        }}
                        onClear={() => setContractTypeId(undefined)}
                    />
                    <Select
                        {...tariffTypeSelectProps}
                        placeholder="Nom du type de tariff"
                        allowClear
                        style={{marginRight: "60px", width: "250px" }}
                        onSelect={(value: RawValueType | LabelInValueType) => {
                            typeof value === 'string' && setTariffTypeId(value)
                        }}
                        onClear={() => setTariffTypeId(undefined)}
                    />
                </div>
                <Table
                    {...tableProps}
                    pagination={{
                        ...tableProps.pagination,
                        position: ['bottomRight'],
                        size: 'small',
                    }}
                    rowKey="id"
                >
                    <Table.Column dataIndex="name" title="Nom Contrat" sorter />
                    <Table.Column dataIndex={['commercialOffer', 'name']} title="Nom Offre" />
                    <Table.Column dataIndex={['contractType', 'name']} title="Type de Contrat" />
                    <Table.Column dataIndex={['tariffType', 'name']} title="Type de Tarif" />
                    <Table.Column<IContract>
                        title="Actions"
                        dataIndex="actions"
                        render={(_, record) => (
                            <Space>
                                <EditButton hideText size="small" recordItemId={record.id} />
                                <DeleteButton hideText size="small" recordItemId={record.id} />
                            </Space>
                        )}
                    />
                </Table>
            </List>
        </div>
    )
}
