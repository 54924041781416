import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import { ListNrlinksInfos } from 'src/modules/NrlinksManagement/NrlinksInfos'
import { NrlinksManagementMenuConfigName } from 'src/configs'

import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'

/**
 * Resource NrlinksInfos Name.
 */
export const resourceNrlinksInfosName = 'nrlinksInfos'

const nrlinksInfosUrls = getResourceUrlsFromResourceName(resourceNrlinksInfosName, NrlinksManagementMenuConfigName)

/**
 * NrlinksInfos Resource config.
 */
export const NrlinksInfosResourceConfig: IRefineResourceConfig = {
    name: resourceNrlinksInfosName,
    meta: {
        parent: NrlinksManagementMenuConfigName,
        label: `Informations nrLINK`,
    },
    list: nrlinksInfosUrls.list,
}
/**
 * NrlinksInfosRoutes.
 */
export const nrlinksInfosRoutes: RouteProps[] = [
    {
        path: nrlinksInfosUrls.list,
        Component: ListNrlinksInfos,
    },
]
