import { SupplyContractsRoutes } from 'src/modules/SupplyContracts/SupplyContractsConfig'
import { AdvicesRoutes } from 'src/modules/Advices/AdvicesConfig'
import { LogisticsRoutes } from 'src/modules/Logistics/LogisticsConfig'
import { NrlinksManagementRoutes } from 'src/modules/NrlinksManagement/NrlinksManagementConfig'
import { InformationRoutes } from 'src/modules/Information/InformationConfig'

/**
 * Refine App Routes.
 */
export const routes = [
    ...SupplyContractsRoutes,
    ...AdvicesRoutes,
    ...LogisticsRoutes,
    ...NrlinksManagementRoutes,
    ...InformationRoutes,
]
