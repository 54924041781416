import React from 'react'
import { List, useTable } from '@refinedev/antd'
import { Table } from 'antd'
import { useParams } from 'react-router-dom'
import { INrlink } from 'src/modules/Logistics/ChameleonDeliveries/ChameleonDeliveries.d'

/**
 * This function returns a view of a Chameleon Deliveries details.
 *
 * @returns View to show details of a Chameleon Deliveries.
 */
export const ShowChameleonDeliveries = () => {
    const { id } = useParams()
    const { tableProps } = useTable<INrlink>({
        dataProviderName: 'logisticDataProvider',
        resource: 'nrlink',
        meta: {
            operation: 'allNrlinks',
            fields: [
                'totalCount',
                {
                    edges: [
                        {
                            node: [
                                'id',
                                'erlGuid',
                                'ihdGuid',
                                'erlModelNumber',
                                'ihdModelNumber',
                                'erlManufacturerMonth',
                                'ihdManufacturerMonth',
                                'erlManufacturerYear',
                                'ihdManufacturerYear',
                                'erlFirmwareVersion',
                                'ihdFirmwareVersion',
                                'erlReconditionedStatus',
                                'ihdReconditionedStatus',
                                {
                                    carton: ['identifier', { pallet: ['identifier'] }],
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        filters: {
            permanent: [
                {
                    field: 'initialChameleonDeliveryId',
                    operator: 'eq',
                    value: id,
                },
            ],
        },
    })

    return (
        <>
            <List
                title="Liste des Nrlinks pour cette livraison"
                headerProps={{
                    extra: <></>,
                }}
            >
                <Table
                    {...tableProps}
                    pagination={{
                        ...tableProps.pagination,
                        position: ['bottomRight'],
                        size: 'small',
                    }}
                    rowKey="id"
                >
                    <Table.Column dataIndex="erlGuid" title="ERL Guid" />
                    <Table.Column dataIndex="ihdGuid" title="IHD Guid" />

                    <Table.Column dataIndex={['carton', 'identifier']} title="Identifiant carton" />
                    <Table.Column dataIndex={['carton', 'pallet', 'identifier']} title="Identifiant pallet" />

                    <Table.Column dataIndex="erlModelNumber" title="ERL Numero Modele" />
                    <Table.Column dataIndex="erlManufacturerMonth" title="ERL Mois construction" />
                    <Table.Column dataIndex="erlManufacturerYear" title="ERL Année construction" />
                    <Table.Column dataIndex="erlFirmwareVersion" title="ERL Fimware version" />
                    <Table.Column dataIndex="erlReconditionedStatus" title="ERL Reconditionné" />

                    <Table.Column dataIndex="ihdModelNumber" title="IHD Numero Modele" />
                    <Table.Column dataIndex="ihdManufacturerMonth" title="IHD Mois Construction" />
                    <Table.Column dataIndex="erlManufacturerYear" title="IHD Année construction" />
                    <Table.Column dataIndex="ihdFirmwareVersion" title="IHD Fimware version" />
                    <Table.Column dataIndex="ihdReconditionedStatus" title="IHD Reconditionné" />
                </Table>
            </List>
        </>
    )
}
