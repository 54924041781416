import { IRefineResourceConfig } from 'src/configs/interfaces.d'
import NrlinkWidgets from 'src/modules/NrlinksManagement/NrlinkWidgets'
import { NrlinksManagementMenuConfigName } from 'src/configs'

import { getResourceUrlsFromResourceName } from 'src/modules/utils'
import { RouteProps } from 'react-router-dom'

/**
 * Resource NrlinkWidgets Name.
 */
export const resourceNrlinkWidgetsName = 'nrlinkWidgets'

const nrlinkWidgetsUrls = getResourceUrlsFromResourceName(resourceNrlinkWidgetsName, NrlinksManagementMenuConfigName)

/**
 * NrlinkWidgets Resource config.
 */
export const NrlinkWidgetsResourceConfig: IRefineResourceConfig = {
    name: resourceNrlinkWidgetsName,
    meta: {
        parent: NrlinksManagementMenuConfigName,
        label: 'Widgets nrLINKs',
    },
    list: nrlinkWidgetsUrls.list,
}

/**
 * NrlinkWidgetsRoutes.
 */
export const nrlinkWidgetsRoutes: RouteProps[] = [
    {
        path: nrlinkWidgetsUrls.list,
        Component: NrlinkWidgets,
    },
]
